import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from '../utils/firebase';
import styles from '../styles/Blormstream.module.css';
import Navbar from '../components/Navbar';

export default function Blormstream() {
  const [mints, setMints] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchMetadata = async (uri) => {
    try {
      const response = await fetch(uri);
      const metadata = await response.json();
      return metadata;
    } catch (error) {
      console.error('Error fetching metadata:', error);
      return null;
    }
  };

  const fetchMints = async () => {
    setLoading(true);
    try {
      const mintQuery = query(
        collection(db, 'tokenMinted'),
        orderBy('timestamp', 'desc'),
        limit(18) // Updated to 18
      );
  
      const querySnapshot = await getDocs(mintQuery);
      const newMints = [];
      const contractSet = new Set();
  
      for (const doc of querySnapshot.docs) {
        const mintData = doc.data();
        console.log('mintData:', mintData);
        if (mintData.contractAddress && !contractSet.has(mintData.contractAddress)) {
          const metadata = await fetchMetadata(mintData.tokenURI);
          newMints.push({ 
            ...mintData, 
            id: doc.id, 
            isNew: true,
            image: metadata?.image || '/placeholder.svg?height=200&width=200'
          });
          contractSet.add(mintData.contractAddress);
        } else {
          console.log("Skipping duplicate or missing contract:", mintData.contractAddress);
        }
      }      
  
      setMints(prevMints => {
        const updatedMints = [...newMints, ...prevMints].reduce((acc, mint) => {
          if (!acc.some(m => m.contractAddress === mint.contractAddress)) {
            acc.push(mint);
          }
          return acc;
        }, []);
        return updatedMints.slice(0, 18); // Updated to 18
      });
  
      setLoading(false);
    } catch (error) {
      console.error('Error fetching mints:', error);
      setLoading(false);
    }
  };
  

  useEffect(() => {
    fetchMints();
    const interval = setInterval(fetchMints, 30000); // Fetch every 30 seconds
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setMints(prevMints =>
        prevMints.map((mint, index) => ({ ...mint, isNew: index === 0 ? true : false }))
      );
    }, 4000); // Changed from 5000 to 4000 milliseconds
    return () => clearTimeout(timer);
  }, [mints]);

  const abbreviateAddress = (address) => {
    if (!address) return 'N/A'; // Fallback if the address is undefined or null
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };
  

  const formatNumber = (num) => {
    return new Intl.NumberFormat('en-US', { 
      style: 'currency', 
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(num);
  };

  return (
    <div className={styles.container}>
        <Navbar />
      <h1 className={styles.title}>BLORMSTREAM</h1>
      <div className={styles.mintList}>
        {mints.map((mint, index) => (
          <div
            key={mint.id}
            className={`${styles.mintCard} ${index === 0 && mint.isNew ? styles.newMint : ''}`}
          >
            <div className={styles.imageContainer}>
              <img src={mint.image} alt={`Token ${mint.tokenId}`} className={styles.tokenImage} />
            </div>
            <div className={styles.cardContent}>
              <div className={styles.section}>
                <h2 className={styles.contractName}>{mint.contractDetails.name}</h2>
                <p className={styles.contractAddress}>{abbreviateAddress(mint.contractAddress)}</p>
              </div>
              <div className={styles.section}>
                <p className={styles.stat}>TVL: {(mint.totalValueLocked)} USDC</p>
                <p className={styles.stat}>Yield: {(mint.totalYield)} USDC</p>
                <p className={styles.stat}>Blints: {mint.totalMints}</p>
              </div>
              <div className={styles.section}>
                <p className={styles.timestamp}>
                  Latest Blint: {new Date(mint.timestamp.seconds * 1000).toLocaleString()}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      {loading && <p className={styles.loading}>Loading...</p>}
    </div>
  );
}
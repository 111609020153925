import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';

const Blormery = () => {
    return (
        <div>
            <h1>Blormery</h1>
        </div>
    );
}

export default Blormery;
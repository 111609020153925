import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import BlintLanding from "./pages/blint/BlintLanding";
import Profile from "./pages/Profile";
import ProfilePublic from "./pages/ProfilePublic";
import Blormery from "./pages/Blormery";
import Blormstream from "./pages/Blormstream";
import Generator from "./pages/blint/Generator";
import "./styles/App.css";
import { AuthProvider } from "./context/AuthContext";
import BlintPage from "./pages/BlintPage";
import BlormmyBrain from "./pages/Blormmy-Brain";
import DefiDictionary from "./pages/DefiDictionary";
// Import specific components for Blop, Polypepen, and Rayquaza
import Opepen from "./pages/blint/Opepen";
import Polypepen from "./pages/blint/Polypepen";
import Rayquaza from "./pages/blint/Rayquaza";
import Terms from "./pages/Terms";
function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blint" element={<BlintLanding />} />

          {/* Specific Routes for Opepen, Polypepen, and Rayquaza */}
          <Route path="/blint/blop" element={<Opepen />} />
          <Route path="/blint/polypepen" element={<Polypepen />} />
          <Route path="/blint/blormquaza" element={<Rayquaza />} />

          {/* Generic BlintPage for other routes */}
          <Route path="/blint/:slug" element={<BlintPage />} />

          <Route path="/user/:userAddress" element={<ProfilePublic />} />

          <Route path="/profile" element={<Profile />} />
          <Route path="/blormery" element={<Blormery />} />
          <Route path="/blormstream" element={<Blormstream />} />
          <Route path="/Blormmy-Brain" element={<BlormmyBrain />} />
          <Route path="/defi-dictionary" element={<DefiDictionary />} />
          <Route path="/terms" element={<Terms />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;

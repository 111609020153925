import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import generatorData from '../utils/generatorData.json';  // Import the generator data
import blintCollections from '../utils/blintCollections.json';  // Import blintCollections
import Generator from './blint/Generator';  // Import the Generator component

const BlintPage = () => {
  const { slug } = useParams();
  const generatorConfig = generatorData[slug];
  const [manifestData, setManifestData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Find the collection based on the slug
  const collectionData = blintCollections.find(collection => collection.path === `/blint/${slug}`);

  useEffect(() => {
    const fetchManifest = async () => {
      if (generatorConfig && generatorConfig.manifest) {
        try {
          const response = await fetch(generatorConfig.manifest);
          if (!response.ok) {
            throw new Error(`Failed to fetch manifest: ${response.statusText}`);
          }
          const data = await response.json();
          setManifestData(data);
          setLoading(false);
        } catch (err) {
          setError(err.message);
          setLoading(false);
        }
      } else {
        setError('Invalid generator configuration');
        setLoading(false);
      }
    };

    fetchManifest();
  }, [generatorConfig]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!generatorConfig || !manifestData) {
    return <div>Generator not found</div>;
  }

  // Handle the case where no contractAddress is found in the collection data
  const contractAddress = collectionData ? collectionData.contractAddress : 'Contract address not found';

  return (
    <Generator
      title={generatorConfig.title}
      layers={generatorConfig.layers}
      contractAddress={contractAddress}  // Use the dynamic contract address
      manifest={manifestData}  // Pass the fetched manifest data
      publicFolder={generatorConfig.folder}
    />
  );
};

export default BlintPage;

import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import { ChevronLeft, ChevronRight, ChevronUp, ChevronDown } from 'lucide-react';
import styles from '../../styles/BlintLanding.module.css';
import blintCollections from '../../utils/blintCollections.json';
import Navbar from '../../components/Navbar';
import { db } from '../../utils/firebase'; // Import Firestore instance
import { doc, getDoc } from 'firebase/firestore'; // Import Firestore functions

// Button component
const Button = ({ children, onClick, className, size = 'default', variant = 'default', disabled }) => {
    const sizeClass = styles[`button${size.charAt(0).toUpperCase() + size.slice(1)}`];
    const variantClass = styles[`button${variant.charAt(0).toUpperCase() + variant.slice(1)}`];

    return (
        <button
            onClick={onClick}
            className={`${styles.button} ${sizeClass} ${variantClass} ${className}`}
            disabled={disabled}
        >
            {children}
        </button>
    );
};

const BlintLanding = () => {
    const [carouselCollections, setCarouselCollections] = useState([]);
    const [listViewCollections, setListViewCollections] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: 'blints', direction: 'descending' });
    const carouselRef = useRef(null);
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(false);

    // Fetch Firestore data for each collection by contractAddress (the document ID)
    const fetchContractDataFromFirestore = useCallback(async (contractAddress) => {
        try {
            const docRef = doc(db, 'contractData', contractAddress);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                return docSnap.data(); // Return the contract details from Firestore
            } else {
                console.log(`No document found for contract address: ${contractAddress}`);
                return null;
            }
        } catch (error) {
            console.error(`Error fetching Firestore data for contract address ${contractAddress}:`, error);
            return null;
        }
    }, []);

    const fetchAndSortCollections = useCallback(async () => {
        const updatedCollections = await Promise.all(blintCollections.map(async (collection) => {
            const contractData = await fetchContractDataFromFirestore(collection.contractAddress);

            if (contractData) {
                // Format and store TVL and Yield with appropriate decimal places
                const tvlValue = parseFloat(contractData.totalValueLocked || '0') / 1e6;
                const yieldValue = parseFloat(contractData.totalYield || '0') / 1e6;

                return {
                    ...collection,
                    tvl: tvlValue !== 0 ? tvlValue.toLocaleString(undefined, { minimumFractionDigits: 6, maximumFractionDigits: 6 }) : '0',
                    yield: yieldValue !== 0 ? yieldValue.toLocaleString(undefined, { minimumFractionDigits: 6, maximumFractionDigits: 6 }) : '0',
                    blints: contractData.totalMints || 0,
                };
            } else {
                return {
                    ...collection,
                    tvl: '0',
                    yield: '0',
                    blints: 0,
                };
            }
        }));

        setCarouselCollections(updatedCollections.sort((a, b) => parseFloat(b.tvl) - parseFloat(a.tvl)));  // Always sorted by 'tvl' for carousel
        setListViewCollections(updatedCollections);  // This will be sorted based on user interaction
    }, [fetchContractDataFromFirestore]);


    useEffect(() => {
        fetchAndSortCollections();
    }, [fetchAndSortCollections]);

    const sortedListViewCollections = useMemo(() => {
        if (!sortConfig) return listViewCollections;

        // Sort listViewCollections based on sortConfig
        const sorted = [...listViewCollections].sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });

        // Remove duplicates for the list view
        const uniqueCollections = [];
        const seenContracts = new Set();

        for (const collection of sorted) {
            if (!seenContracts.has(collection.contractAddress)) {
                uniqueCollections.push(collection);
                seenContracts.add(collection.contractAddress);
            }
        }

        // Assign ranks after removing duplicates
        return uniqueCollections.map((collection, index) => ({
            ...collection,
            rank: index + 1,
        }));
    }, [listViewCollections, sortConfig]);

    useEffect(() => {
        const handleScroll = () => {
            if (carouselRef.current) {
                const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
                setCanScrollLeft(scrollLeft > 0);
                setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
            }
        };

        const carouselElement = carouselRef.current;
        if (carouselElement) {
            carouselElement.addEventListener('scroll', handleScroll);
            handleScroll(); // Call it initially to set the correct state
        }

        return () => {
            if (carouselElement) {
                carouselElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, [carouselCollections]);

    const sortBy = useCallback((key) => {
        setSortConfig((prevConfig) => {
            const direction = prevConfig.key === key && prevConfig.direction === 'ascending' ? 'descending' : 'ascending';
            return { key, direction };
        });
    }, []);

    const getSortIcon = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'ascending' ? <ChevronUp style={{ paddingTop: '5px', width: '16px', height: '16px', boxSizing: 'border-box' }} /> : <ChevronDown style={{ paddingTop: '5px', width: '16px', height: '16px', boxSizing: 'border-box' }} />;
        }
        return null;
    };

    const topCarouselCollections = useMemo(() => carouselCollections.slice(0, 10), [carouselCollections]);

    const scrollCarousel = (direction) => {
        if (carouselRef.current) {
            const scrollAmount = carouselRef.current.offsetWidth * 0.8;
            const currentScroll = carouselRef.current.scrollLeft;
            const newScroll = direction === 'left' ? currentScroll - scrollAmount : currentScroll + scrollAmount;
            carouselRef.current.scrollTo({ left: newScroll, behavior: 'smooth' });
        }
    };

    const handleCollectionClick = (path) => {
        window.location.href = path;
    };

    // Find the collection titled "UWUAZAS" or default to the first collection
const bannerCollection = useMemo(() => {
    return carouselCollections.find(collection => collection.title === "UWUAZAS") || carouselCollections[0] || {};
}, [carouselCollections]);


    return (
        <div className={styles.container}>
            <Navbar />

            <div className={styles.rainbowBanner}>
                <div className={styles.rainbowContent}>
                    <p className={styles.rainbowText}>a blint is an nft that costs $1 and earns you rewards</p>
                </div>
            </div>

            {/* Banner Section */}
            <div className={styles.bannerWrapper}>
                <div className={styles.banner} onClick={() => handleCollectionClick(bannerCollection.path)}>
                    <div className={styles.bannerContent}>
                        <div>
                            <h1 className={styles.title}>{bannerCollection.title}</h1>
                            <p className={styles.subtitle}>{bannerCollection.description}</p>
                        </div>
                        <div>
                            <p className={styles.stats}>TVL: {bannerCollection.tvl} USDC</p>
                            <p className={styles.stats}>Yield: {bannerCollection.yield} USDC</p>
                        </div>
                        <div>
                            <Button size="sm">BLINT</Button>
                        </div>
                    </div>
                    <div className={styles.bannerImageWrapper}>
                        <img src={bannerCollection.image} alt={bannerCollection.title} className={styles.bannerImage} />
                    </div>
                </div>
            </div>

            {/* Carousel Collections */}
            {carouselCollections.length > 0 && (
                <div className={styles.collectionsSection}>
                    <div className={styles.sectionHeader}>
                        <h2 className={styles.sectionTitle}>COLLECTIONS</h2>
                        <div>
                            <Button onClick={() => scrollCarousel('left')} size="sm" variant="outline" disabled={!canScrollLeft}>
                                <ChevronLeft style={{ width: '16px', height: '16px' }} />
                            </Button>
                            <Button onClick={() => scrollCarousel('right')} size="sm" variant="outline" disabled={!canScrollRight}>
                                <ChevronRight style={{ width: '16px', height: '16px' }} />
                            </Button>
                        </div>
                    </div>
                    <div className={styles.carousel} ref={carouselRef}>
                        {topCarouselCollections.map((collection, index) => (
                            <div key={index} className={styles.card} onClick={() => handleCollectionClick(collection.path)}>
                                <div className={styles.cardImageWrapper}>
                                    <div className={styles.cardImage} style={{ backgroundImage: `url(${collection.image})` }}></div>
                                </div>
                                <div className={styles.cardContent}>
                                    <h3 className={styles.cardTitle}>{collection.title}</h3>
                                    <div>
                                        <p className={styles.cardStat}>TVL</p>
                                        <p className={styles.cardStatValue}>{collection.tvl} USDC</p>
                                    </div>
                                    <div>
                                        <p className={styles.cardStat}>YIELD</p>
                                        <p className={styles.cardStatValue}>{collection.yield} USDC</p>
                                    </div>
                                    <div>
                                        <p className={styles.cardStat}>BLINTS</p>
                                        <p className={styles.cardStatValue}>{collection.blints.toLocaleString()}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {/* All Collections List */}
            <div className={styles.table}>
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead className={styles.tableHeader}>
                        <tr>
                            <th onClick={() => sortBy('rank')} className={styles.tableHeaderCell}>Rank {getSortIcon('rank')}</th>
                            <th onClick={() => sortBy('title')} className={styles.tableHeaderCell}>Collection {getSortIcon('title')}</th>
                            <th onClick={() => sortBy('tvl')} className={styles.tableHeaderCell}>TVL {getSortIcon('tvl')}</th>
                            <th onClick={() => sortBy('yield')} className={styles.tableHeaderCell}>Yield {getSortIcon('yield')}</th>
                            <th onClick={() => sortBy('blints')} className={styles.tableHeaderCell}>Blints {getSortIcon('blints')}</th>
                        </tr>
                    </thead>
                    <tbody className={styles.tableBody}>
                        {sortedListViewCollections.map((collection) => (
                            <tr key={collection.contractAddress} className={styles.tableRow} onClick={() => handleCollectionClick(collection.path)}>
                                <td className={styles.tableCell}>{collection.rank}</td>
                                <td className={styles.tableCell}>
                                    <div className={styles.collectionCell}>
                                        <img src={collection.image} alt={collection.title} className={styles.collectionImage} />
                                        <span>{collection.title}</span>
                                    </div>
                                </td>
                                <td className={styles.tableCell}>{collection.tvl} USDC</td>
                                <td className={styles.tableCell}>{collection.yield} USDC</td>
                                <td className={styles.tableCell}>{collection.blints.toLocaleString()}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default BlintLanding;

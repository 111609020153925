import React, { useLayoutEffect, useState, useRef, useEffect, useMemo, useCallback } from 'react';
import BlintDisplayMessage from '../../components/BlintDisplayMessage';
import predefinedColors from '../../utils/predefinedColors.json';
import colorEmbeddings from '../../utils/colorEmbeddings.json';
import styles from '../../styles/Polypepen.module.css';
import UploadToIPFS from '../../utils/UploadToIPFS.js';
import { mintToken, mintTokenWithWallet } from '../../utils/mintToken.js';
import FuzzySet from 'fuzzyset.js';
import BlintCongrats from '../../components/BlintCongrats.js';
import OpepenGrid from '../../components/OpepenGridPurple.js';
import Navbar from '../../components/Navbar.js';
import { useAuth } from '../../context/AuthContext';
import LoadingBlorm from '../../components/LoadingBlorm.js';
import { debounce } from 'lodash';
import blintCollections from '../../utils/blintCollections.json';
import { Link } from 'react-router-dom';

const layers = [
    { id: 'layer0', label: 'Layer 0 (background)', type: 'gradient' },
    { id: 'layer1', label: 'Layer 1', src: '/polypepen/1.png' },
    { id: 'layer2', label: 'Layer 2', src: '/polypepen/2.png' },
    { id: 'layer3', label: 'Layer 3', src: '/polypepen/3.png' },
    { id: 'layer4', label: 'Layer 4', src: '/polypepen/4.png' },
    { id: 'layer5', label: 'Layer 5', src: '/polypepen/5.png' },
    { id: 'layer6', label: 'Layer 6', src: '/polypepen/6.png' },
    { id: 'layer7', label: 'Layer 7', src: '/polypepen/7.png' },
    { id: 'layer8', label: 'Layer 8', src: '/polypepen/8.png' },
    { id: 'layer9', label: 'Layer 9', src: '/polypepen/9.png' },
    { id: 'layer10', label: 'Layer 10', src: '/polypepen/10.png' },
    { id: 'layer11', label: 'Layer 11', src: '/polypepen/11.png' },
    { id: 'layer12', label: 'Layer 12', src: '/polypepen/12.png' },
    { id: 'layer13', label: 'Layer 13', src: '/polypepen/13.png' },
    { id: 'layer14', label: 'Layer 14', src: '/polypepen/14.png' },
    { id: 'layer15', label: 'Layer 15', src: '/polypepen/15.png' },
    { id: 'layer16', label: 'Layer 16', src: '/polypepen/16.png' },
    { id: 'layer17', label: 'Layer 17', src: '/polypepen/17.png' },
    { id: 'layer18', label: 'Layer 18', src: '/polypepen/18.png' },
    { id: 'layer19', label: 'Layer 19', src: '/polypepen/19.png' },
    { id: 'logo', label: 'Logo', src: '/logo2.png' }
];


const hexToRgb = (hex) => {
    const bigint = parseInt(hex.slice(1), 16);
    return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
};

const colorDistance = (rgb1, rgb2) => {
    return Math.sqrt(
        Math.pow(rgb1[0] - rgb2[0], 2) +
        Math.pow(rgb1[1] - rgb2[1], 2) +
        Math.pow(rgb1[2] - rgb2[2], 2)
    );
};

const clamp = (value, min, max) => Math.min(Math.max(value, min), max);

const getRandomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

const sampleRandomShade = (rgb) => {
    return [
        clamp(rgb[0] + getRandomInt(-10, 10), 0, 255),
        clamp(rgb[1] + getRandomInt(-10, 10), 0, 255),
        clamp(rgb[2] + getRandomInt(-10, 10), 0, 255)
    ];
};

const rgbArrayToHex = (rgb) => {
    return `#${rgb.map(x => x.toString(16).padStart(2, '0')).join('')}`;
};

const Polypepen = () => {
    // Geoblocking State Variables
    const [isBlocked, setIsBlocked] = useState(false);
    const [isLoadingGeo, setIsLoadingGeo] = useState(true);

    // **Geoblocking Effect**
    useEffect(() => {
        const fetchGeolocation = async () => {
            try {
                const response = await fetch('https://ipapi.co/json/');
                if (!response.ok) {
                    throw new Error(`Geolocation API error: ${response.statusText}`);
                }
                const data = await response.json();
                const userCountry = data.country;

                if (userCountry === 'US') {  // Block access from the US
                    setIsBlocked(true);
                }
            } catch (error) {
                console.error('Error fetching geolocation data:', error);
                // Optionally, you can choose to block access if geolocation fails
                // setIsBlocked(true);
            } finally {
                setIsLoadingGeo(false);
            }
        };

        fetchGeolocation();
    }, []);

    const [nftTitle, setNftTitle] = useState('POLYPEPEN');
    const [contractAddress, setContractAddress] = useState('');

    useEffect(() => {
        const blormCollection = blintCollections.find(collection => collection.title === 'POLYPEPEN');
        if (blormCollection) {
            setNftTitle(blormCollection.title);
            setContractAddress(blormCollection.contractAddress);
        }
    }, []);

    const [isMobile, setIsMobile] = useState(false);
    // Set initial state from JSON

    const checkIfMobile = useCallback(() => {
        if (typeof window !== 'undefined') {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            const isMobileDevice = /android|iphone|ipad|iPod|opera mini|iemobile|wpdesktop/i.test(userAgent) ||
                window.innerWidth <= 768;
            setIsMobile(isMobileDevice);
        }
    }, []);

    useEffect(() => {
        checkIfMobile();
        window.addEventListener('resize', checkIfMobile);
        return () => window.removeEventListener('resize', checkIfMobile);
    }, []);

    useLayoutEffect(() => {
        const canvas = canvasRef.current;
        if (canvas) {
            if (isMobile) {
                canvas.width = 1000; // Reduced size for mobile
                canvas.height = 1000; // Reduced size for mobile
            } else {
                canvas.width = 2000;
                canvas.height = 2000;
            }
        }
        resizeBanner(); // Set initial banner size
    }, [isMobile]);


    const [bannerSize, setBannerSize] = useState('5vw');
    const [marginSize, setMarginSize] = useState('.35rem');
    const [bannerRows, setBannerRows] = useState(2);

    const resizeBanner = useCallback(() => {
        const isMobileDevice = window.innerWidth <= 768 || window.innerHeight <= 500;
        // console.log(`Window width: ${window.innerWidth}, Window height: ${window.innerHeight}`);
        // console.log(`Is mobile device: ${isMobileDevice}`);
        setBannerSize(isMobileDevice ? '12.5vw' : '5vw');
        setMarginSize(isMobileDevice ? '.35rem' : '.35rem');
    }, []);

    const OpepenGridTop = useMemo(() => (
        <OpepenGrid rows={bannerRows} imageSize={bannerSize} margin={marginSize} />
    ), [bannerRows, bannerSize, marginSize]);

    const OpepenGridBottom = useMemo(() => (
        <OpepenGrid rows={bannerRows} imageSize={bannerSize} margin={marginSize} />
    ), [bannerRows, bannerSize, marginSize]);

    const { user, walletAddress, profile, handleLogin, wallet } = useAuth();
    const [showModal, setShowModal] = useState(false);

    const [displayMessage, setDisplayMessage] = useState([]);
    const [showCongrats, setShowCongrats] = useState(false);

    const [loading, setLoading] = useState(false);

    const [tokenUrl, setTokenUrl] = useState('');

    const [layerColors, setLayerColors] = useState({
        layer0: '',
        layer1: '',
        layer2: '',
        layer3: '',
        layer4: '',
        layer5: '',
        layer6: '',
        layer7: '',
    });

    const canvasRef = useRef(null);
    const [canvasDataURL, setCanvasDataURL] = useState('');
    const [uploadUrl, setUploadUrl] = useState('');
    const [checkResult, setCheckResult] = useState('');
    const [addResult, setAddResult] = useState('');

    const adjustBrightness = (rgb, factor) => {
        return rgb.map(channel => Math.max(Math.min(Math.round(channel * factor), 255), 0));
    };

    const lightPurple = [200, 160, 254];  // Light purple base color
    const mediumPurple = [160, 100, 254]; // Medium purple base color
    const pastelPurple = [220, 190, 254]; // Pastel purple base color
    const darkPurple = [120, 70, 200];    // Dark purple base color

    const basePurples = [lightPurple, mediumPurple, pastelPurple, darkPurple];

    const isWithinRange = (color, baseColor, range) => {
        return color.every((channel, index) => Math.abs(channel - baseColor[index]) <= range);
    };

    const clampColor = (value) => {
        return Math.max(0, Math.min(value, 255));
    };

    const generateRandomPurple = (baseColor, brightnessFactor) => {
        let adjustedColor = adjustBrightness(baseColor, brightnessFactor);

        // Ensure the color is within the specified range of the base color
        if (!isWithinRange(adjustedColor, baseColor, 10)) {
            adjustedColor = baseColor.map((channel, index) =>
                clampColor(channel + getRandomInt(-10, 10))
            );
        }

        return rgbArrayToHex(adjustedColor);
    };

    const getRandomBasePurple = () => {
        const randomIndex = getRandomInt(0, basePurples.length - 1);
        return basePurples[randomIndex];
    };

    // Function to ensure the background color is distinct
    const ensureDistinctBackground = (background, otherColors) => {
        const threshold = 100; // Adjust this value as needed for color difference tolerance
        let isDistinct = true;

        for (const color of otherColors) {
            if (colorDistance(hexToRgb(background), hexToRgb(color)) < threshold) {
                isDistinct = false;
                break;
            }
        }

        return isDistinct;
    };

    const generateLayerColors = (backgroundWhiteChance = 0.1) => {
        let backgroundColor;
        const otherLayerColors = [];

        let whiteLayerCount = 0;

        // Generate other layer colors
        for (let i = 1; i < layers.length; i++) {
            if (layers[i].id !== 'logo') {
                let color;
                if (whiteLayerCount < 2 && Math.random() < 0.2) {
                    color = generateRandomWhite();
                    whiteLayerCount++;
                } else {
                    color = generateRandomPurple(getRandomBasePurple(), getRandomInt(8, 12) / 10);
                }
                otherLayerColors.push(color);
            } else {
                otherLayerColors.push(null); // No color for the logo
            }
        }

        // Ensure we have no more than 2 white layers
        while (whiteLayerCount > 2) {
            for (let i = 0; i < otherLayerColors.length; i++) {
                if (otherLayerColors[i] === 'white') {
                    otherLayerColors[i] = generateRandomPurple(getRandomBasePurple(), getRandomInt(8, 12) / 10);
                    whiteLayerCount--;
                    if (whiteLayerCount <= 2) break;
                }
            }
        }

        // Generate background color with a configurable chance for white
        if (Math.random() < backgroundWhiteChance) {
            backgroundColor = generateRandomWhite();
        } else {
            do {
                backgroundColor = generateRandomPurple(getRandomBasePurple(), getRandomInt(8, 12) / 10);
            } while (otherLayerColors.includes(backgroundColor));
        }

        // Map generated colors back to layers
        const layerColors = {
            layer0: backgroundColor // Background color
        };

        for (let i = 1; i < layers.length; i++) {
            if (layers[i].id !== 'logo') {
                layerColors[`layer${i}`] = otherLayerColors[i - 1];
            }
        }

        return layerColors;
    };



    const generateRandomWhite = () => {
        const whiteValue = getRandomInt(245, 255);
        return rgbArrayToHex([whiteValue, whiteValue, whiteValue]);
    };

    // Helper functions
    const getRandomInt = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const rgbArrayToHex = (rgbArray) => {
        return `#${rgbArray.map(channel => channel.toString(16).padStart(2, '0')).join('')}`;
    };

    const [firstRender, setFirstRender] = useState(true);

    const renderCanvas = useCallback(
        debounce(async () => {
            setFirstRender(false);
            const newLayerColors = generateLayerColors();
            setLayerColors(newLayerColors);

            const canvas = canvasRef.current;
            if (canvas) {
                const ctx = canvas.getContext('2d');
                await renderLayers(ctx, newLayerColors);
                const isEmpty = isCanvasEmpty(canvas);
                setIsCanvasValid(!isEmpty);
                if (!isEmpty) {
                    setCanvasDataURL(canvas.toDataURL('image/png'));
                } else {
                    setCanvasDataURL('');
                }
            }
        }, 300), []); // Adjust debounce delay as needed


    const isCanvasEmpty = (canvas) => {
        const ctx = canvas.getContext('2d');
        const pixelBuffer = new Uint32Array(
            ctx.getImageData(0, 0, canvas.width, canvas.height).data.buffer
        );
        return !pixelBuffer.some(color => color !== 0);
    };

    const rgbToHex = (r, g, b) => {
        const toHex = (value) => {
            const hex = value.toString(16);
            return hex.length === 1 ? `0${hex}` : hex;
        };
        return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
    };

    const adjustColor = (color, amount) => {
        return Math.max(Math.min(color + amount, 255), 0);
    };

    const generateGradientColors = (baseColor) => {
        const rgb = hexToRgb(baseColor);
        const primaryColor = rgbToHex(
            adjustColor(rgb[0], 20),
            adjustColor(rgb[1], 20),
            adjustColor(rgb[2], 20)
        );
        const secondaryColor = rgbToHex(
            adjustColor(rgb[0], -20),
            adjustColor(rgb[1], -20),
            adjustColor(rgb[2], -20)
        );
        return { primaryColor, secondaryColor };
    };

    const applyGradientMap = (imageData, primaryColor, secondaryColor) => {
        const data = imageData.data;
        const primary = hexToRgb(primaryColor);
        const secondary = hexToRgb(secondaryColor);

        for (let i = 0; i < data.length; i += 4) {
            const alpha = data[i + 3];
            if (alpha === 0) continue;

            const grayscale = data[i] * 0.3 + data[i + 1] * 0.59 + data[i + 2] * 0.11;
            const t = grayscale / 255;

            data[i] = Math.round(primary[0] * (1 - t) + secondary[0] * t);
            data[i + 1] = Math.round(primary[1] * (1 - t) + secondary[1] * t);
            data[i + 2] = Math.round(primary[2] * (1 - t) + secondary[2] * t);
        }

        return imageData;
    };

    const drawStars = (ctx) => {
        const count = getRandomInt(1, 5);
        setStarCount(count);
        const starImage = new Image();
        starImage.src = '/whitestar2.png';
        starImage.onload = () => {
            for (let i = 0; i < count; i++) {
                const x = getRandomInt(0, ctx.canvas.width);
                const y = getRandomInt(0, ctx.canvas.height);
                const angle = getRandomInt(0, 360);
                const size = getRandomInt(50, 100);
                ctx.save();
                ctx.translate(x, y);
                ctx.rotate((angle * Math.PI) / 180);
                ctx.drawImage(starImage, -size / 2, -size / 2, size, size);
                ctx.restore();
            }
        };
    };

    const clearCanvas = (ctx) => {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    };

    const renderLayers = async (ctx, newLayerColors) => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        const offscreenCanvas = document.createElement('canvas');
        offscreenCanvas.width = canvas.width;
        offscreenCanvas.height = canvas.height;
        const offscreenCtx = offscreenCanvas.getContext('2d');

        const { primaryColor: bgPrimaryColor, secondaryColor: bgSecondaryColor } = generateGradientColors(newLayerColors.layer0);
        const gradient = offscreenCtx.createLinearGradient(0, 0, offscreenCanvas.width, offscreenCanvas.height);
        gradient.addColorStop(0, bgPrimaryColor);
        gradient.addColorStop(1, bgSecondaryColor);
        offscreenCtx.fillStyle = gradient;
        offscreenCtx.fillRect(0, 0, offscreenCanvas.width, offscreenCanvas.height);

        const loadImage = (src) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.crossOrigin = 'anonymous';
                img.onload = () => resolve(img);
                img.onerror = () => {
                    console.error(`Failed to load image: ${src}`);
                    reject(new Error(`Failed to load image: ${src}`));
                };
                img.src = src;
            });
        };


        const layerImages = await Promise.all(layers.slice(1, -1).map(layer => loadImage(layer.src)));
        const logoImage = await loadImage('/logo2.png');

        ctx.drawImage(offscreenCanvas, 0, 0, canvas.width, canvas.height);

        for (let i = 0; i < layers.slice(1, -1).length; i++) {
            const layer = layers.slice(1, -1)[i];
            const img = layerImages[i];

            offscreenCtx.clearRect(0, 0, offscreenCanvas.width, offscreenCanvas.height);
            offscreenCtx.drawImage(img, 0, 0, offscreenCanvas.width, offscreenCanvas.height);

            let adjustedImageData;
            const imageData = offscreenCtx.getImageData(0, 0, offscreenCanvas.width, offscreenCanvas.height);
            const { primaryColor, secondaryColor } = generateGradientColors(newLayerColors[layer.id]);
            adjustedImageData = applyGradientMap(imageData, primaryColor, secondaryColor);
            offscreenCtx.putImageData(adjustedImageData, 0, 0);

            ctx.drawImage(offscreenCanvas, 0, 0, canvas.width, canvas.height);
        }

        // Draw the logo at the bottom right corner with 40% transparency
        ctx.globalAlpha = 0.4;
        const logoSize = isMobile ? 50 : 100;
        const logoX = canvas.width - logoSize - 20; // Adjust 20 for padding
        const logoY = canvas.height - logoSize - 20; // Adjust 20 for padding
        ctx.drawImage(logoImage, logoX, logoY, logoSize, logoSize);

        // Reset globalAlpha to 1 to ensure other drawings are not affected
        ctx.globalAlpha = 1;

        setCanvasDataURL(canvas.toDataURL('image/png'));
    };


    const [starCount, setStarCount] = useState(0);
    const [recipientAddress, setRecipientAddress] = useState('');

    const updateMetadata = () => {
        const newMetadata = {
            /*
            {name: `POLYPEPEN`, 
            attributes: [
                { trait_type: 'Layer 0 Color', value: layerColors.layer0 },
                { trait_type: 'Layer 1 Color', value: layerColors.layer1 },
                { trait_type: 'Layer 2 Color', value: layerColors.layer2 },
                { trait_type: 'Layer 3 Color', value: layerColors.layer3 },
                { trait_type: 'Layer 4 Color', value: layerColors.layer4 },
                { trait_type: 'Layer 5 Color', value: layerColors.layer5 },
                { trait_type: 'Layer 6 Color', value: layerColors.layer6 },
                { trait_type: 'Layer 7 Color', value: layerColors.layer7 },            
            ],
            */
            description: `Blorm everything.`,
            image: '',
            creator: 'BLORM',
            motto: 'Blorm everything.',
            collection: 'POLYPEPEN',
            external_url: 'https://blorm.xyz'
        };
        setMetadata(newMetadata);
    };

    useEffect(() => {
        updateMetadata();
    }, [layerColors, starCount]);

    const loadImage = (src) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = 'anonymous';
            img.onload = () => {
                resolve(img);
            };
            img.onerror = () => {
                reject(new Error(`Failed to load image: ${src}`));
            };
            img.src = src;
        });
    };

    const clearMessage = (index) => {
        setDisplayMessage((prevMessages) => prevMessages.filter((_, i) => i !== index));
    };


    const [metadata, setMetadata] = useState({});
    const [successTxHash, setSuccessTxHash] = useState('');
    const [successTokenId, setSuccessTokenId] = useState('');
    const [openseaURL, setOpenseaURL] = useState('');

    const [nft, setNft] = useState(null);

    const handleUploadAndMint = async () => {
        if (!user || !walletAddress || !profile) {
            setShowModal(true);
            return;
        }



        try {


            setLoading(true);
            setTokenUrl('Loading...');

            const uri = await UploadToIPFS(canvasDataURL);
            const updatedMetadata = { ...metadata, image: uri };
            setMetadata(updatedMetadata);

            const txResponse = await mintTokenWithWallet(updatedMetadata, contractAddress, wallet, 1);
            const tokenId = txResponse[0];
            const txHash = txResponse[1];
            setSuccessTxHash(txHash);
            if (tokenId === undefined) {
                setDisplayMessage([...displayMessage, { message: 'Failed to get token ID.', type: 'error' }]);
                return;
            }
            setSuccessTokenId(tokenId);

            setLoading(false);
            setNft({ metadata: updatedMetadata, tokenId, chain: 'Polygon', chainId: '137' });
            setShowCongrats(true);
        } catch (error) {
            setLoading(false);
            // console.error('Uploading and minting:', error);
            setDisplayMessage([...displayMessage, { message: 'There was an issue with uploading and minting. Please ensure you have sufficient funds and try again.', type: 'error' }]);
        }
    };


    const handleClose = () => {
        setDisplayMessage([]);
    };

    useEffect(() => {
        if (user && showModal) {
            setShowModal(false);
        }
    }, [user, profile, walletAddress]);

    const [isCanvasValid, setIsCanvasValid] = useState(false);

    const blintAgainClicked = () => {
        setShowCongrats(false);
        setCanvasDataURL('');
        setSuccessTxHash('');
        setSuccessTokenId('');
        setOpenseaURL('');
        setNft(null);
        setStarCount(0);
        setRecipientAddress('');
        setLayerColors({
            layer0: '',
            layer1: '',
            layer2: '',
            layer3: '',
            layer4: '',
            layer5: '',
            layer6: '',
            layer7: '',
        });
        setMetadata({});
        setTokenUrl('');
        setUploadUrl('');
        setCheckResult('');
        setAddResult('');
        setCanvasDataURL('');
        setDisplayMessage([]);
        setFirstRender(true);
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (event.target.className.includes('modal')) {
                setShowModal(false);
            }
        };

        if (showModal) {
            window.addEventListener('click', handleOutsideClick);
        } else {
            window.removeEventListener('click', handleOutsideClick);
        }

        return () => {
            window.removeEventListener('click', handleOutsideClick);
        };
    }, [showModal]);

    // **Conditional Rendering Based on Geoblocking**
    if (isLoadingGeo) {
        return (
            <div className={styles.loadingContainer}>
                <LoadingBlorm />
            </div>
        );
    }

    if (isBlocked) {
        return (
            <div className={styles.blockedContainer}>
                <Link to="/blint" style={{ color: 'white' }}> ← back to landing</Link>
                <h1>Access Denied</h1>
                <p>This page is not available in your country.</p>
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <Navbar />
            {displayMessage && displayMessage.length > 0 && (
                <BlintDisplayMessage messages={displayMessage} clearMessage={clearMessage} />
            )}
            {OpepenGridTop}
            {loading ? <LoadingBlorm /> : null}
            {showCongrats ? (
                <BlintCongrats
                    txHash={successTxHash}
                    tokenId={successTokenId}
                    openseaURL={openseaURL}
                    nft={nft}
                    blintAgainClicked={blintAgainClicked}
                />
            ) : (
                <div className={styles.middleContainer}>
                    <div className={styles.canvasContainer}>
                        <div className={styles.canvasInner}>
                            <canvas ref={canvasRef} width={2000} height={2000} className={styles.canvas}></canvas>
                        </div>
                    </div>
                    <div className={styles.buttonsContainer}>
                        <div className={styles.generateButtonContainer}>
                            <button className={styles.actionButton} onClick={renderCanvas}>
                                <img src="/sparkle.svg" alt="Sparkle" className={styles.sparkle} width="28" height="28" />
                                Generate
                            </button>
                        </div>
                        {!firstRender && (
                            <div className={styles.uploadButtonContainer}>
                                <button className={styles.actionButton} onClick={handleUploadAndMint}>
                                    <img src="/chain.svg" alt="chain" className={styles.sparkle} width="20" height="20" />
                                    &nbsp; Mint
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            )}
            {showModal && (
                <div className={styles.modal}>
                    <div className={styles.modalContent}>
                        <h2>Sign in to mint your NFT</h2>
                        <button onClick={handleLogin} className={styles.actionButton}>Sign in</button>
                    </div>
                </div>
            )}
            {OpepenGridBottom}
        </div>
    );
};

export default Polypepen;

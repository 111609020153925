import React, { useState, useEffect } from 'react';
import styles from '../styles/LoadingBlorm.module.css';
import logo from '../images/logo.png'; // Path to your logo image
import { Link } from 'react-router-dom';

const LoadingBlorm = () => {
  const [isMobile, setIsMobile] = useState(false);

  const checkIfMobile = () => {
    if (typeof window !== 'undefined') {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      const isMobileDevice = /android|iphone|ipad|iPod|opera mini|iemobile|wpdesktop/i.test(userAgent);
      setIsMobile(isMobileDevice);
    }
  };

  useEffect(() => {
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  const logoSrc = isMobile ? '/blorm_animated_logo.mov' : '/blorm_animated_logo.webm';

  return (
    <div className={styles.container}>
      <div className={styles.background}></div>
      <div className={styles.center}>
        <video
          src={logoSrc}
          alt="Animated Logo"
          className={styles.centerImage}
          autoPlay
          muted
          loop
          controls={false}
          playsInline
        />
        <span className={styles.centerText}>blorming in action</span>
      </div>
    </div>
  );
};

export default LoadingBlorm;

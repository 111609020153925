// utils/openaiUtils.js
import OpenAI from "openai";
import { ethers } from 'ethers';

const openai = new OpenAI({ apiKey: process.env.REACT_APP_OPENAI_API_KEY, dangerouslyAllowBrowser: true });

export const gpt4 = async (messages) => {
  try {
    const completion = await openai.chat.completions.create({
      messages: messages,
      model: "gpt-4",
    });

    return completion.choices[0];
  } catch (error) {
    console.error("Error calling OpenAI API:", error);
    throw error;
  }
};

export const getGpt4Colors = async (prompt) => {
  try {
    const completion = await openai.chat.completions.create({
      messages: [
        {
          role: "system",
          content: "You are a text to color assistant in an image generator. Given a text prompt, provide three colors to create an image: a background color, a primary body color, and a secondary highlight color. Choose the closest colors to the colors, sentiment, and descriptions in the prompt. Be extremely specific and choose niche and rare hex colors if possible. Respond only in the following JSON format without markdown or ``` surrounding the response: {\"background\": \"#RRGGBB\", \"primary\": \"#RRGGBB\", \"secondary\": \"#RRGGBB\"}"
        },
        { role: "user", content: prompt },
      ],
      model: "gpt-4o-mini",
    });

    const colors = completion.choices[0].message.content;
    // console.log("gpt.js getGpt4Colors colors:", colors);
    return JSON.parse(colors);
  } catch (error) {
    console.error("Error calling OpenAI API:", error);
    throw error;
  }
};


export const gpt4WithFunctionCalling = async (messages, tools) => {
  try {
    const completion = await openai.chat.completions.create({
      model: "gpt-4o-mini",
      messages: messages,
      tools: tools,
      tool_choice: "auto",
    });

    console.log("gpt.js gpt4WithFunctionCalling completion:", completion);

    return completion.choices[0];
  } catch (error) {
    console.error("Error calling OpenAI API:", error);
    throw error;
  }
};

export const initiateSendEther = async (amount, recipient) => {
  try {
    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();

    const tx = await signer.sendTransaction({
      to: recipient,
      value: ethers.parseEther(amount.toString()),
    });

    await tx.wait();
    return tx;
  } catch (error) {
    console.error("Error sending Ether:", error);
    throw error;
  }
};


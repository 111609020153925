import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/Navbar.module.css';
import x from '../images/x.png';
import tg from '../images/tg.png';
import AuthButton from './AuthButton';
import hamburgerIcon from '../images/hamburger.png';
import logo from '../images/logo.png';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [scrollY, setScrollY] = useState(0);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false); // Tooltip state
  const totalFrames = 167; // Total number of frames

  // Function to preload images
  const preloadImages = () => {
    for (let i = 1; i <= totalFrames; i++) {
      const img = new Image();
      img.src = `/blorm-logo-frames/webp_converted/frame${i}.webp`;
    }
  };

  useEffect(() => {
    preloadImages(); // Preload images when component mounts
  }, []);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Handle scroll position
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(Math.floor(window.scrollY)); // Ensure scrollY is an integer
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Calculate the current frame based on scroll position
  const getCurrentFrame = () => {
    const frameIndex = Math.floor(scrollY % totalFrames); // Ensure frameIndex is an integer
    // If frameIndex is 0, set it to the first frame
    return `/blorm-logo-frames/webp_converted/frame${frameIndex === 0 ? 1 : frameIndex}.webp`;
  };

  const handleMouseEnter = () => {
    setIsTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setIsTooltipVisible(false);
  };

  return (
    <div className={styles.navbar}>
      <div className={styles.navbarContent}>
        {isMobile ? (
          <>
            {/* Mobile version */}
            <div className={styles.navbarIcons}>
              <a href="https://x.com/blorm_" target="_blank" rel="noopener noreferrer">
                <img src={x} alt="X" className={styles.logoLink} />
              </a>
              <a href="https://t.me/+XsidUbJTqkA3M2Qx" target="_blank" rel="noopener noreferrer">
                <img src={tg} alt="tg" className={styles.logoLink} />
              </a>
            </div>
            <Link to="/" className={styles.navbarLinkTag}>
              <div className={styles.navbarLogo}>
                <img src={logo} alt="Logo" className={styles.logoImage} />
                <span className={styles.logoText}>BLORM</span>
              </div>
            </Link>
            <div className={styles.navbarRight}>
              <AuthButton />
              <div className={styles.hamburger} onClick={toggleMenu}>
                <img src={hamburgerIcon} alt="Menu" />
              </div>
            </div>
            {isOpen && (
              <div className={styles.dropdownMenu}>
                <Link to="/blint" className={styles.navBarLinkText}>⟢ BLINT</Link>
              </div>
            )}
          </>
        ) : (
          <>
            {/* Desktop version */}
            <div className={styles.navbarLinks}>
              <Link
                to="/blint"
                className={styles.navBarLinkText}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                ⟢ BLINT
              </Link>
              {isTooltipVisible && (
                <div className={styles.tooltip}>
                  mint a nft through the magic of blorm
                </div>
              )}
            </div>
            <Link to="/" className={styles.navbarLinkTag}>
              <div className={styles.navbarLogo}>
                <img src={getCurrentFrame()} alt="Logo" className={styles.logoImage} />
                <span className={styles.logoText}>BLORM</span>
              </div>
            </Link>
            <div className={styles.navbarLinks}>
              <a href="https://x.com/blorm_" target="_blank" rel="noopener noreferrer">
                <img src={x} alt="X" className={styles.logoLink} />
              </a>
              <a href="https://t.me/+XsidUbJTqkA3M2Qx" target="_blank" rel="noopener noreferrer">
                <img src={tg} alt="tg" className={styles.logoLink} />
              </a>
              <AuthButton />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Navbar;

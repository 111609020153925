import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../context/AuthContext';
import styles from '../styles/Profile.module.css';
import ProfilePicDropdown from '../components/ProfilePicDropdown';
import { ethers } from 'ethers';
import Navbar from '../components/Navbar';
import AuthButton from '../components/AuthButton';
import editIcon from '../images/edit-pen-white.png';
import onft721 from '../utils/BLORM_ONFT721.json';
import blintCollections from '../utils/blintCollections.json';
import { Grid, List, ChevronDown, X, Loader } from 'lucide-react';
import polygonLogo from '../images/polygon.png';
import { Alchemy, Network } from 'alchemy-sdk';

const getPinataGateway = (url) => {
  if (url && url.startsWith('https://ipfs.io/ipfs/')) {
    const ipfsHash = url.replace('https://ipfs.io/ipfs/', '');
    return `${process.env.REACT_APP_GATEWAY_URL}/ipfs/${ipfsHash}`;
  }
  return url;
};

export default function Profile() {
  const { user, walletAddress, profile, updateUserProfile } = useAuth();
  const [localProfile, setLocalProfile] = useState(profile);
  const [editingProfile, setEditingProfile] = useState(false);
  const [nftData, setNftData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [activeCollection, setActiveCollection] = useState("ALL");
  const [viewMode, setViewMode] = useState('grid');
  const [chainsWithNfts, setChainsWithNfts] = useState(new Set());
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [editedProfile, setEditedProfile] = useState({ ...profile });
  const [claimedNfts, setClaimedNfts] = useState({});
  const [loadingNfts, setLoadingNfts] = useState({});
  const [claimTooltips, setClaimTooltips] = useState({});
  const [isClaimAllTooltipVisible, setIsClaimAllTooltipVisible] = useState(false);

  const settings = {
    apiKey: process.env.REACT_APP_ALCHEMY_APY_KEY, // Replace with your Alchemy API Key
    network: Network.MATIC_MAINNET, // Replace with your desired network, e.g., Network.ETH_MAINNET or Network.MATIC_MAINNET
  };

  const alchemy = new Alchemy(settings);

  const tvlRefs = useRef({});
  const yieldRefs = useRef({});

  const handleClaimMouseEnter = (nftKey) => {
    setClaimTooltips((prev) => ({ ...prev, [nftKey]: true }));
  };

  const handleClaimMouseLeave = (nftKey) => {
    setClaimTooltips((prev) => ({ ...prev, [nftKey]: false }));
  };

  const handleClaimAllMouseEnter = () => {
    setIsClaimAllTooltipVisible(true);
  };

  const handleClaimAllMouseLeave = () => {
    setIsClaimAllTooltipVisible(false);
  };

  useEffect(() => {
    setLocalProfile(profile);
    setEditedProfile(profile);
  }, [profile]);

  useEffect(() => {
    if (walletAddress) {
      fetchNfts();
    }
  }, [walletAddress]);

  const fetchNfts = async () => {
    setLoadingNfts(true);
    const allNfts = [];

    const fetchPage = async (pageKey = null) => {
      try {
        const response = await alchemy.nft.getNftsForOwner(walletAddress, {
          pageSize: 100,
          pageKey: pageKey
        });

        const filteredResponseNfts = response.ownedNfts.filter(nft =>
          blintCollections.some(collection => collection.contractAddress.toLowerCase() === nft.contract.address.toLowerCase())
        );

        const nftsWithMetadata = filteredResponseNfts.map(nft => ({
          tokenId: nft.tokenId,
          contractAddress: nft.contract.address,
          metadata: nft,
          collectionName: nft.contract.name || nft.collection?.name,
          imageUrl: getPinataGateway(nft.image?.pngUrl || nft.image?.cachedUrl || nft.image?.originalUrl),
          uniqueKey: `polygon-${nft.tokenId}-${nft.contract.address}`,
        }));

        allNfts.push(...nftsWithMetadata);

        if (response.pageKey) {
          await fetchPage(response.pageKey);
        }
      } catch (error) {
        console.error('Error fetching NFTs:', error);
      }
    };

    try {
      await fetchPage();
      setNftData(allNfts);
    } finally {
      setLoadingNfts(false);
    }
  };


  const fetchTvl = async (contract, userAddress, tokenId) => {
    try {
      const tvl = await contract.getSingleAccountBalance(userAddress, tokenId);
      return ethers.formatUnits(tvl, 6);
    } catch (error) {
      console.error("Error fetching TVL:", error);
      return 'N/A';
    }
  };

  const fetchYield = async (contract, userAddress, tokenId) => {
    try {
      const yieldValue = await contract.getSingleAccountYield(userAddress, tokenId);
      return ethers.formatUnits(yieldValue, 6);
    } catch (error) {
      console.error("Error fetching Yield:", error);
      return 'N/A';
    }
  };

  const handleClaim = async (tokenId, contractAddress) => {
    if (!walletAddress) {
      return;
    }

    try {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const network = await provider.getNetwork();

      if (network.chainId !== 137) {
        try {
          await provider.send("wallet_switchEthereumChain", [{ chainId: "0x89" }]);
        } catch (switchError) {
          if (switchError.code === 4902) {
            await provider.send("wallet_addEthereumChain", [
              {
                chainId: "0x89",
                chainName: "Polygon Mainnet",
                rpcUrls: ["https://polygon-rpc.com/"],
                nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
                blockExplorerUrls: ["https://polygonscan.com/"]
              }
            ]);
          } else {
            console.log(switchError);
          }
        }
      }

      const signer = await provider.getSigner();
      const abi = onft721.abi;
      const contract = new ethers.Contract(contractAddress, abi, signer);

      const tx = await contract.withdrawFromYearn(tokenId, { gasLimit: 3500000 });
      await tx.wait();

      setClaimedNfts((prev) => ({ ...prev, [tokenId]: true }));
      setTimeout(() => setClaimedNfts((prev) => ({ ...prev, [tokenId]: false })), 5000); // Reset after 5 seconds
    } catch (error) {
      console.error('Error claiming yield:', error);
    }
  };

  const handleClaimAll = async () => {
    if (!walletAddress) {
      return;
    }

    try {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const network = await provider.getNetwork();

      if (network.chainId !== 137) {
        try {
          await provider.send("wallet_switchEthereumChain", [{ chainId: "0x89" }]);
        } catch (switchError) {
          if (switchError.code === 4902) {
            await provider.send("wallet_addEthereumChain", [
              {
                chainId: "0x89",
                chainName: "Polygon Mainnet",
                rpcUrls: ["https://polygon-rpc.com/"],
                nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
                blockExplorerUrls: ["https://polygonscan.com/"]
              }
            ]);
          } else {
            console.log(switchError);
          }
        }
      }

      const signer = await provider.getSigner();
      const collection = blintCollections.find(c => c.contractAddress === activeCollection);
      if (!collection) {
        console.log('Collection not found:', activeCollection);
        return;
      }
      const contractAddress = collection.contractAddress;
      const abi = onft721.abi;
      const contract = new ethers.Contract(contractAddress, abi, signer);

      const tx = await contract.withdrawAllFromYearn({ gasLimit: 5000000 });
      await tx.wait();
    } catch (error) {
      console.error('Error claiming all yields:', error);
    }
  };

  const handleEditClick = () => {
    if (user) {
      setEditingProfile(true);
    } else {
      setShowModal(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedProfile(prev => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    await updateUserProfile(editedProfile);
    setLocalProfile(editedProfile);
    setEditingProfile(false);
  };

  const handleCollectionChange = (collection) => {
    setActiveCollection(collection);
    setIsDropdownOpen(false);
  };

  const handleProfilePicChange = (option) => {
    setEditedProfile(prev => ({ ...prev, profilePicture: option }));
  };

  const sampleProfile = {
    name: 'BLORMER',
    bio: 'BLORM INFORMATION ON CHAIN',
    profilePicture: "https://gateway.pinata.cloud/ipfs/QmY1HTnkpzJUFVZ9QWo1j51w88NZsV5ZpA32dtv4At9gki"
  };

  const filteredNfts = activeCollection === "ALL"
    ? nftData
    : nftData.filter(nft => nft.contractAddress.toLowerCase() === activeCollection.toLowerCase());

  const sortedCollections = [...blintCollections].sort((a, b) => {
    const aCount = nftData.filter(nft => nft.metadata.collection === a.title).length;
    const bCount = nftData.filter(nft => nft.metadata.collection === b.title).length;
    return bCount - aCount;
  });

  const profilePicOptions = nftData.map(nft => ({
    value: nft.tokenId,
    label: `${nft.collectionName} - ${nft.tokenId}`,
    imageUrl: nft.imageUrl,
    metadata: nft.metadata
  }));

  const abbreviateAddress = (address) => {
    if (!address) return '';
    return `${address.slice(0, 6)}...${address.slice(-5)}`;
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      for (const nft of nftData) {
        try {
          const contract = new ethers.Contract(nft.contractAddress, onft721.abi, new ethers.BrowserProvider(window.ethereum));
          const newTvl = await fetchTvl(contract, walletAddress, nft.tokenId);
          const newYield = await fetchYield(contract, walletAddress, nft.tokenId);

          if (tvlRefs.current[nft.uniqueKey]) {
            tvlRefs.current[nft.uniqueKey].innerText = `${newTvl} USDC`;
            tvlRefs.current[nft.uniqueKey].classList.add(styles.blink);
          }
          if (yieldRefs.current[nft.uniqueKey]) {
            yieldRefs.current[nft.uniqueKey].innerText = `${newYield} USDC`;
            yieldRefs.current[nft.uniqueKey].classList.add(styles.blink);
          }

          setTimeout(() => {
            if (tvlRefs.current[nft.uniqueKey]) {
              tvlRefs.current[nft.uniqueKey].classList.remove(styles.blink);
            }
            if (yieldRefs.current[nft.uniqueKey]) {
              yieldRefs.current[nft.uniqueKey].classList.remove(styles.blink);
            }
          }, 1000);
        } catch (error) {
          console.error('Error updating TVL and Yield:', error);
        }
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [nftData, walletAddress]);

  if (!user || !localProfile.eth_address) {
    return (
      <div onClick={() => setShowModal(true)} className={styles.container}>
        <Navbar />
        <div className={styles.profileTop}>
          <div className={styles.profilePicture}>
            <img src={sampleProfile.profilePicture} alt="Profile" className={styles.profileImage} />
          </div>
          <div className={styles.profileInfo}>
            <h2 className={styles.profileName}>{sampleProfile.name}</h2>
            <p className={styles.profileBio}>{sampleProfile.bio}</p>
            <p className={styles.profileAddress}>0x...12345</p>
          </div>
        </div>
        {showModal && (
          <div className={styles.modal}>
            <div className={styles.modalContent}>
              <h2>Sign In To View Your Profile</h2>
              <AuthButton />
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Navbar />
      <div className={styles.profileTop}>
        <div className={styles.profilePicture}>
          <img src={localProfile.profilePicture} alt="Profile" className={styles.profileImage} />
          <img src={editIcon} alt="Edit" className={styles.editIcon} onClick={handleEditClick} />
        </div>
        <div className={styles.profileInfo}>
          <h2 className={styles.profileName}>{localProfile.name}</h2>
          <p className={styles.profileBio}>{localProfile.bio}</p>
          <p className={styles.profileAddress}>{abbreviateAddress(walletAddress)}</p>
        </div>
        <div className={styles.chainIcons}>
          {Array.from(chainsWithNfts).map(chainId => (
            <img key={chainId} src={polygonLogo} alt="Polygon" className={styles.chainIcon} />
          ))}
        </div>
      </div>

      {loadingNfts ? (
        <div className={styles.loadingContainer}>
          <Loader className={styles.loadingAnimation} />
        </div>
      ) : nftData.length > 0 ? (
        <div className={styles.nftsContainer}>
          <h3 className={styles.blintTitle}>BLINTS</h3>
          <div className={styles.nftsToolbar}>
            <div className={styles.nftsToolbarLeft}>
              <div className={styles.collectionDropdownContainer}>
                <div
                  className={styles.collectionDropdown}
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                >
                  <div className={styles.selectedOption}>
                    {activeCollection === "ALL" ? (
                      <span>ALL</span>
                    ) : (
                      <>
                        <img
                          src={blintCollections.find(c => c.contractAddress === activeCollection)?.image}
                          alt={activeCollection}
                          className={styles.collectionImage}
                        />
                        <span>{blintCollections.find(c => c.contractAddress === activeCollection)?.title || activeCollection}</span>
                      </>
                    )}
                  </div>
                  <ChevronDown size={20} />
                </div>

                {isDropdownOpen && (
                  <div className={styles.dropdownMenu}>
                    <div
                      className={styles.dropdownItem}
                      onClick={() => handleCollectionChange("ALL")}
                    >
                      <span className={styles.collectionTitle}>ALL</span>
                    </div>

                    {blintCollections.map(collection => (
                      <div
                        key={collection.contractAddress}
                        className={styles.dropdownItem}
                        onClick={() => handleCollectionChange(collection.contractAddress)}
                      >
                        <img src={collection.image} alt={collection.name} className={styles.collectionImage} />
                        <span className={styles.collectionTitle}>{collection.title || collection.contractAddress}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {activeCollection !== "ALL" && (
                <div className={styles.claimAllButtonContainer}>
                  <button
                    className={styles.claimAllButton}
                    onClick={handleClaimAll}
                    onMouseEnter={handleClaimAllMouseEnter}
                    onMouseLeave={handleClaimAllMouseLeave}
                    disabled={loadingNfts}
                  >
                    CLAIM ALL
                  </button>
                  {isClaimAllTooltipVisible && (
                    <div className={styles.tooltipClaimAll}>Claim total yield from collection.</div>
                  )}
                </div>
              )}
            </div>
            <div className={styles.viewToggle}>
              <button onClick={() => setViewMode('grid')} className={viewMode === 'grid' ? styles.active : ''}>
                <Grid size={24} />
              </button>
              <button onClick={() => setViewMode('list')} className={viewMode === 'list' ? styles.active : ''}>
                <List size={24} />
              </button>
            </div>
          </div>
          <div className={styles.nftContainer}>
            {viewMode === 'grid' ? (
              <div className={styles.nftGrid}>
                {filteredNfts.map(nft => (
                  <div key={nft.uniqueKey} className={styles.nftCard}>
                    <img
                      src={nft.imageUrl}
                      alt={nft.metadata.name || 'NFT Image'}
                      className={styles.nftCardImage}
                    />
                    <div className={styles.nftDetails}>
                      <div className={styles.nftField}><strong>COLLECTION:</strong> {nft.metadata.collection?.name}</div>
                      <div className={styles.nftField}><strong>TOKEN ID:</strong> {nft.tokenId}</div>
                      <div className={styles.nftField}>
                        <strong>CHAIN:</strong>
                        <div>
                          Polygon <img src={polygonLogo} alt="Polygon" className={styles.cardChainIcon} />
                        </div>
                      </div>
                      <div className={styles.nftField}><strong>TVL:</strong> <span ref={el => tvlRefs.current[nft.uniqueKey] = el}>{nft.tvl} USDC</span></div>
                      <div className={styles.nftField}><strong>YIELD:</strong> <span ref={el => yieldRefs.current[nft.uniqueKey] = el}>{nft.yieldValue} USDC</span></div>
                      <button
                        className={`${styles.claimButton} ${claimedNfts[nft.tokenId] ? styles.claimed : ''}`}
                        onClick={() => handleClaim(nft.tokenId, nft.contractAddress)}
                        onMouseEnter={() => handleClaimMouseEnter(nft.uniqueKey)}
                        onMouseLeave={() => handleClaimMouseLeave(nft.uniqueKey)}
                        disabled={claimedNfts[nft.tokenId] || loadingNfts[nft.tokenId]}
                      >
                        {loadingNfts[nft.tokenId] ? 'CLAIMING...' : claimedNfts[nft.tokenId] ? 'CLAIMED' : 'CLAIM'}
                      </button>
                      {claimTooltips[nft.uniqueKey] && (
                        <div className={styles.tooltip}>Claim yield from a blint.</div>
                      )}
                    </div>

                  </div>
                ))}
              </div>
            ) : (
              <div className={styles.nftListWrapper}>
                <table className={styles.nftList}>
                  <thead>
                    <tr>
                      <th>Image</th>
                      <th>Collection</th>
                      <th>Token ID</th>
                      <th>TVL</th>
                      <th>Yield</th>
                      <th>Chain</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredNfts.map((nft) => (
                      <tr key={nft.uniqueKey}>
                        <td>
                          <img
                            src={nft.imageUrl}
                            alt={nft.metadata.name}
                            className={styles.nftImage} />
                        </td>
                        <td>{nft.collectionName}</td>
                        <td>{nft.tokenId}</td>
                        <td ref={el => tvlRefs.current[nft.uniqueKey] = el}>{nft.tvl} USDC</td>
                        <td ref={el => yieldRefs.current[nft.uniqueKey] = el}>{nft.yieldValue} USDC</td>
                        <td>
                          <img src={polygonLogo} alt="Polygon" className={styles.chainIcon} /> {nft.chain}
                        </td>
                        <td>
                          <button
                            className={`${styles.claimButton} ${claimedNfts[nft.tokenId] ? styles.claimed : ''}`}
                            onClick={() => handleClaim(nft.tokenId, nft.contractAddress)}
                            disabled={claimedNfts[nft.tokenId] || loadingNfts[nft.tokenId]}
                          >
                            {loadingNfts[nft.tokenId] ? 'CLAIMING...' : claimedNfts[nft.tokenId] ? 'CLAIMED' : 'CLAIM'}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className={styles.noBlintsFound}>
          <h3>No blints found</h3>
        </div>
      )}
      {editingProfile && (
        <div className={styles.editPanel} onClick={() => setEditingProfile(false)}>
          <div className={styles.editContent} onClick={(e) => e.stopPropagation()}>
            <div className={styles.editHeader}>
              <h2>Edit Profile</h2>
              <button className={styles.closeButton} onClick={() => setEditingProfile(false)}>
                <X size={24} />
              </button>
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="name">NAME</label>
              <input
                type="text"
                id="name"
                name="name"
                value={editedProfile.name}
                onChange={handleChange}
                placeholder="Enter your name"
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="bio">BIO</label>
              <input
                type="text"
                id="bio"
                name="bio"
                value={editedProfile.bio}
                onChange={handleChange}
                placeholder="Enter your bio"
              />
            </div>
            <div className={styles.formGroup}>
              <label>PROFILE PICTURE</label>
              <ProfilePicDropdown
                options={profilePicOptions}
                value={editedProfile.profilePicture}
                onChange={handleProfilePicChange}
              />
            </div>
            <div className={styles.editButtons}>
              <button className={styles.cancelButton} onClick={() => setEditingProfile(false)}>Cancel</button>
              <button className={styles.saveButton} onClick={handleSave}>Save</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import styles from '../styles/DefiDictionary.module.css';
import logo from '../images/logo.png';
import arch from '../images/arch.png';
import { Link } from 'react-router-dom';
import homeBg from '../images/home-bg.png';
import mobileBg from '../images/mobile-bg.png';
import xLogo from '../images/x.png';

const DefiDictionary = () => {
    const [isMobile, setIsMobile] = useState(false);

    const checkIfMobile = () => {
        if (typeof window !== 'undefined') {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            const isMobileDevice = /android|iphone|ipad|iPod|opera mini|iemobile|wpdesktop/i.test(userAgent);
            const isSmallScreen = window.innerWidth <= 768; // Adjust the width threshold as needed
            setIsMobile(isMobileDevice || isSmallScreen);
        }
    };

    useEffect(() => {
        checkIfMobile();
        window.addEventListener('resize', checkIfMobile);
        return () => window.removeEventListener('resize', checkIfMobile);
    }, []);

    const MobileView = () => (
        <div className="container-mobile">
            <img className={styles.homeBg} src={mobileBg} alt="home background"></img>
            <div className={styles.topleft}>
                <a href="https://x.com/blorm_" className={styles.topLeftImage} target="_blank" rel="noopener noreferrer">
                    <img className={styles.topleftImage} src={xLogo} alt="x logo" />
                </a>
            </div>
            <div className={styles.topCenter}>
                <span className={styles.topCenterText}>BLORM</span>
            </div>
            <div className={styles.topRight}>
                <a href="https://x.com/blorm_" className={styles.topRightImage} target="_blank" rel="noopener noreferrer">
                    <img className={styles.topRightImage} src={arch} alt="arch logo" />
                </a>
            </div>
            <div className={styles.center}>
                <img 
                    src="/defidict-cover.png" 
                    className={styles.mobileCover} 
                    alt="DeFi Dictionary Cover"
                />
            </div>
            <div className={styles.bottomLeft}>
                learning defi is
                <br/>
                front running wallstreet
            </div>
            <div className={styles.bottomRight}>
                <a
                    href="/blorm-defi-dictionary.pdf"
                    className={styles.actionButton}
                    download="blorm-defi-dictionary.pdf"
                >
                    DOWNLOAD
                </a>
            </div>
        </div>
    );

    const DesktopView = () => (
        <div className="container">
            <img className={styles.homeBg} src={homeBg} alt="home background"></img>
            <a href="https://x.com/blorm_" className={styles.topleft} target="_blank" rel="noopener noreferrer">
                <div className={styles.topleft}>
                    <img className={styles.topleftImage} src={xLogo} alt="x logo" />
                    <span className={styles.topleftText}>&nbsp;/ BLORM</span>
                </div>
            </a>
            <div className={styles.topRight}>
                <span className={styles.topRightText}>150+ KEY TERMS</span>
            </div>
            <div className={styles.bottomLeft}>
                learning defi is front running wallstreet
            </div>
            <div className={styles.bottomRight}>
                <a
                    href="/blorm-defi-dictionary.pdf"
                    className={styles.actionButton}
                    download="blorm-defi-dictionary.pdf"
                >
                    DOWNLOAD
                </a>
            </div>
            <div className={styles.center}>
                <iframe 
                    src="/blorm-defi-dictionary.pdf" 
                    className={styles.pdfViewer} 
                    title="DeFi Dictionary"
                />
            </div>
        </div>
    );

    return isMobile ? <MobileView /> : <DesktopView />;
}

export default DefiDictionary;

import React, {
  useLayoutEffect,
  useState,
  useRef,
  useEffect,
  useCallback,
} from "react";
import BlintDisplayMessage from "../../components/BlintDisplayMessage.js";
import styles from "../../styles/Generator.module.css";
import UploadToIPFS from "../../utils/UploadToIPFS.js";
import { mintTokenWithWallet } from "../../utils/mintToken.js";
import BlintCongrats from "../../components/BlintCongrats.js";
import ChainDropdown from "../../components/ChainDropdown.js";
import Navbar from "../../components/Navbar.js";
import { useAuth } from "../../context/AuthContext.js";
import LoadingBlorm from "../../components/LoadingBlorm.js";
import { debounce } from "lodash";
import { ethers } from "ethers";
import { BarChart, ArrowDown } from "lucide-react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../utils/firebase";
import { Link } from "react-router-dom";
import xIcon from "../../images/x.png"; // Make sure this path matches your image location

const hexToRgb = (hex) => {
  const bigint = parseInt(hex.slice(1), 16);
  return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
};

const clamp = (value, min, max) => Math.min(Math.max(value, min), max);

const getRandomInt = (min, max) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

const sampleRandomShade = (rgb) => {
  return [
    clamp(rgb[0] + getRandomInt(-10, 10), 0, 255),
    clamp(rgb[1] + getRandomInt(-10, 10), 0, 255),
    clamp(rgb[2] + getRandomInt(-10, 10), 0, 255),
  ];
};

const rgbArrayToHex = (rgb) => {
  return `#${rgb.map((x) => x.toString(16).padStart(2, "0")).join("")}`;
};

const Generator = ({
  title,
  layers,
  contractAddress,
  manifest,
  publicFolder,
}) => {
  // Receive props
  // **State Variables**
  const [generatorConfig, setGeneratorConfig] = useState({
    title,
    layers,
    contractAddress,
    manifest,
    publicFolder,
  });

  // Geoblocking State Variables
  const [isBlocked, setIsBlocked] = useState(false);
  const [isLoadingGeo, setIsLoadingGeo] = useState(true);

  // Other State Variables
  const [isMobile, setIsMobile] = useState(false);
  const { user, walletAddress, profile, handleLogin, wallet } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [displayMessage, setDisplayMessage] = useState([]);
  const [showCongrats, setShowCongrats] = useState(false);
  const [loading, setLoading] = useState(false);
  const [layerColors, setLayerColors] = useState({});
  const canvasRef = useRef(null);
  const [canvasDataURL, setCanvasDataURL] = useState("");
  const [firstRender, setFirstRender] = useState(true);
  const [metadata, setMetadata] = useState({});
  const [successTxHash, setSuccessTxHash] = useState("");
  const [successTokenId, setSuccessTokenId] = useState("");
  const [openseaURL, setOpenseaURL] = useState("");
  const [nft, setNft] = useState(null);
  const [isCanvasValid, setIsCanvasValid] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState(2);
  const [nftTitle, setNftTitle] = useState(title);
  const [totalValueLocked, setTotalValueLocked] = useState("0");
  const [totalYield, setTotalYield] = useState("0");
  const [totalBlints, setTotalBlints] = useState(0);
  const [isLoadingStats, setIsLoadingStats] = useState(true);
  const [hasExistingGenerations, setHasExistingGenerations] = useState(false);
  const [blormers, setBlormers] = useState([]);

  // **Geoblocking Effect**
  useEffect(() => {
    const fetchGeolocation = async () => {
      try {
        const response = await fetch("https://ipapi.co/json/");
        if (!response.ok) {
          throw new Error(`Geolocation API error: ${response.statusText}`);
        }
        const data = await response.json();
        const userCountry = data.country;

        if (userCountry === "US") {
          // Block access from the US
          setIsBlocked(true);
        }
      } catch (error) {
        console.error("Error fetching geolocation data:", error);
        // Optionally, you can choose to block access if geolocation fails
        // setIsBlocked(true);
      } finally {
        setIsLoadingGeo(false);
      }
    };

    fetchGeolocation();
  }, []);

  // **Contract Data Fetching**
  const fetchContractData = async () => {
    setIsLoadingStats(true);

    try {
      // Access the document for the given contract address
      const contractDocRef = doc(db, "contractData", contractAddress);
      const contractDoc = await getDoc(contractDocRef);

      if (contractDoc.exists()) {
        const data = contractDoc.data();
        const tvl = data.totalValueLocked / 1e6; // Divide by 10^6 to account for USDC decimals
        const yield_ = data.totalYield / 1e6; // Divide by 10^6 to account for USDC decimals
        const totalMints = data.totalMints;

        // Update the component's state with the adjusted values
        setTotalValueLocked(tvl.toFixed(6)); // Ensure 6 decimal places
        setTotalYield(yield_.toFixed(6)); // Ensure 6 decimal places
        setTotalBlints(totalMints);
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.log("Error fetching contract data from Firebase:", error);
    }

    setIsLoadingStats(false);
  };

  useEffect(() => {
    fetchContractData();
  }, [contractAddress]);

  // **Mobile Detection**
  const checkIfMobile = useCallback(() => {
    if (typeof window !== "undefined") {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      const isMobileDevice =
        /android|iphone|ipad|iPod|opera mini|iemobile|wpdesktop/i.test(
          userAgent
        ) || window.innerWidth <= 768;
      setIsMobile(isMobileDevice);
    }
  }, []);

  useEffect(() => {
    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);
    return () => window.removeEventListener("resize", checkIfMobile);
  }, [checkIfMobile]);

  // **Canvas Setup**
  useLayoutEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      if (isMobile) {
        canvas.width = 1000;
        canvas.height = 1000;
      } else {
        canvas.width = 2000;
        canvas.height = 2000;
      }
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, canvas.width, canvas.height); // Clear canvas with white background
    }
  }, [isMobile]);

  // **Render Canvas on Mount**
  useEffect(() => {
    renderCanvas();
  }, []);

  const renderCanvas = async () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height); // Clear previous drawings

      // Example of rendering an image on the canvas
      const logoImage = await loadImage("/logo2.png");
      const logoSize = 500;
      const logoX = (canvas.width - logoSize) / 2;
      const logoY = (canvas.height - logoSize) / 2;
      ctx.drawImage(logoImage, logoX, logoY, logoSize, logoSize);

      setCanvasDataURL(canvas.toDataURL("image/png")); // Save canvas as data URL
      setIsCanvasValid(true);
    }
  };

  // **Helper Function to Load Images**
  const loadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.onload = () => resolve(img);
      img.onerror = (e) => {
        console.log(`Failed to load image: ${src}`, e);
        reject(new Error(`Failed to load image: ${src}. Error: ${e.message}`));
      };
      img.src = src;
    });
  };

  const isCanvasEmpty = (canvas) => {
    const ctx = canvas.getContext("2d");
    const pixelBuffer = new Uint32Array(
      ctx.getImageData(0, 0, canvas.width, canvas.height).data.buffer
    );
    return !pixelBuffer.some((color) => color !== 0);
  };

  const renderLayers = async (ctx) => {
    const canvas = canvasRef.current;
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    const offscreenCanvas = document.createElement("canvas");
    offscreenCanvas.width = canvas.width;
    offscreenCanvas.height = canvas.height;
    const offscreenCtx = offscreenCanvas.getContext("2d");
    // Use dynamic layers from generatorConfig
    const layerImages = await Promise.all(
      generatorConfig.layers.map((layer) =>
        loadImage(getRandomImage(layer.folder))
      )
    );

    for (let i = 0; i < generatorConfig.layers.length; i++) {
      const img = layerImages[i];
      if (!img) continue;

      offscreenCtx.clearRect(
        0,
        0,
        offscreenCanvas.width,
        offscreenCanvas.height
      );
      offscreenCtx.drawImage(
        img,
        0,
        0,
        offscreenCanvas.width,
        offscreenCanvas.height
      );

      ctx.drawImage(offscreenCanvas, 0, 0, canvas.width, canvas.height);
    }

    // Load and draw the logo after scaling and inverting colors
    const logoImage = await loadImage("/logo2.png");
    const logoCanvas = document.createElement("canvas");
    const logoCtx = logoCanvas.getContext("2d");
    logoCanvas.width = logoImage.width;
    logoCanvas.height = logoImage.height;
    logoCtx.drawImage(logoImage, 0, 0);
    const logoSize = 100;
    const logoX = canvas.width - logoSize - 20;
    const logoY = canvas.height - logoSize - 20;
    ctx.drawImage(logoCanvas, logoX, logoY, logoSize, logoSize);

    setCanvasDataURL(canvas.toDataURL("image/png"));
  };

  const updateMetadata = () => {
    const newMetadata = {
      description: `Blorm everything.`,
      image: "",
      creator: "BLORM",
      motto: "Blorm everything.",
      collection: nftTitle,
      external_url: "https://blorm.xyz",
    };
    setMetadata(newMetadata);
  };

  useEffect(() => {
    updateMetadata();
  }, [layerColors]);

  const clearMessage = (index) => {
    setDisplayMessage((prevMessages) =>
      prevMessages.filter((_, i) => i !== index)
    );
  };

  const handleUploadAndMint = async () => {
    if (!user || !walletAddress || !profile) {
      setShowModal(true);
      return;
    }
    try {
      setLoading(true);

      const uri = await UploadToIPFS(canvasDataURL);
      if (!uri) {
        setLoading(false);
        setDisplayMessage([
          ...displayMessage,
          {
            message:
              "There was an issue with uploading to IPFS. Please try again.",
            type: "error",
          },
        ]);
        return;
      }
      const updatedMetadata = { ...metadata, image: uri };
      setMetadata(updatedMetadata);

      const txResponse = await mintTokenWithWallet(
        updatedMetadata,
        contractAddress,
        wallet,
        quantity
      );
      const tokenId = txResponse[0];
      const txHash = txResponse[1];
      setSuccessTxHash(txHash);
      setSuccessTokenId(tokenId);
      setLoading(false);
      setNft({
        metadata: updatedMetadata,
        tokenId,
        chain: "Polygon",
        chainId: "137",
      });
      setShowCongrats(true);
    } catch (error) {
      setLoading(false);
      console.log("Error minting token:", error);
      setDisplayMessage([
        ...displayMessage,
        {
          message:
            "There was an issue with uploading and minting. Please ensure you have sufficient funds and try again.",
          type: "error",
        },
      ]);
    }
  };

  useEffect(() => {
    if (user && showModal) {
      setShowModal(false);
    }
  }, [user, profile, walletAddress]);

  const blintAgainClicked = () => {
    setShowCongrats(false);
    setCanvasDataURL("");
    setSuccessTxHash("");
    setSuccessTokenId("");
    setOpenseaURL("");
    setNft(null);
    setLayerColors({});
    setMetadata({});
    setCanvasDataURL("");
    setDisplayMessage([]);
    setFirstRender(true);
    setQuantity(1);
    renderCanvas();
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (event.target.className.includes("modal")) {
        setShowModal(false);
      }
    };

    if (showModal) {
      window.addEventListener("click", handleOutsideClick);
    } else {
      window.removeEventListener("click", handleOutsideClick);
    }

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [showModal]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (hasExistingGenerations) {
        e.preventDefault();
        e.returnValue =
          "YOU HAVE EXISTING GENERATIONS, ARE YOU SURE YOU WANT TO EXIT?";
        return e.returnValue;
      }
    };

    if (typeof window !== "undefined") {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      }
    };
  }, [hasExistingGenerations]);

  const handleGenerate = useCallback(
    debounce(async () => {
      const canvas = canvasRef.current;
      if (canvas) {
        const ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        await renderLayers(ctx); // Function to render layers on the canvas

        const isEmpty = isCanvasEmpty(canvas);
        setIsCanvasValid(!isEmpty);
        setHasExistingGenerations(true);

        if (!isEmpty) {
          setCanvasDataURL(canvas.toDataURL("image/png"));
        }
        setFirstRender(false);
      }
    }, 300),
    []
  );

  const handleQuantityChange = (event) => {
    const value = parseInt(event.target.value, 10);
    const newQuantity = isNaN(value) ? 1 : Math.max(1, value);
    setQuantity(newQuantity);
    setPrice(newQuantity * 2);
  };

  useEffect(() => {
    setPrice(quantity * 2);
  }, [quantity]);

  const fetchAllOwnedTokens = async () => {
    const alchemyKey = process.env.REACT_APP_ALCHEMY_API_KEY;

    if (!alchemyKey || !contractAddress) {
      console.error("Missing API key or contract address");
      return;
    }

    const url = `https://polygon-mainnet.g.alchemy.com/nft/v3/${alchemyKey}/getOwnersForContract?contractAddress=${contractAddress}&withTokenBalances=true`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: { accept: "application/json" },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch: ${response.statusText}`);
      }

      const data = await response.json();

      if (!data || !data.owners || data.owners.length === 0) {
        console.log("No owners found");
        return;
      }

      // Map owners and their token balances
      const blormersData = data.owners.map((owner) => ({
        address: owner.ownerAddress,
        blints: owner.tokenBalances.reduce(
          (sum, token) => sum + parseInt(token.balance),
          0
        ),
      }));

      // Sort by the number of BLINTS (tokens) in descending order
      blormersData.sort((a, b) => b.blints - a.blints);

      // Only take the top 10 owners
      const topBlormers = blormersData.slice(0, 10);

      setBlormers(topBlormers);
    } catch (error) {
      console.error("Error fetching owners from Alchemy API:", error);
    }
  };

  useEffect(() => {
    fetchContractData();
    fetchAllOwnedTokens(); // Fetch the owners and tokens
  }, [contractAddress]);

  const abbreviateAddress = (address) => {
    if (!address || address.length < 10) return address;
    return `0x${address.slice(2, 5)}...${address.slice(-4)}`;
  };

  // **Get Random Image Function**
  const getRandomImage = (folder) => {
    const images = generatorConfig.manifest[folder];
    if (!images || images.length === 0) {
      console.log(`No images found for folder: ${folder}`);
      return "";
    }

    const randomIndex = Math.floor(Math.random() * images.length);
    const imagePath = images[randomIndex]; // Get the image name only

    // Properly construct and encode the path
    const folderPath = folder.replace(/\s+/g, ""); // Remove any whitespace
    const fileName = imagePath.split("/").pop(); // Get just the filename
    const encodedFileName = encodeURIComponent(fileName);

    // Construct the full path
    return `/${publicFolder}/${folderPath}/${encodedFileName}`;
  };

  // **Conditional Rendering Based on Geoblocking**
  if (isLoadingGeo) {
    return (
      <div className={styles.loadingContainer}>
        <LoadingBlorm />
      </div>
    );
  }

  if (isBlocked) {
    return (
      <div className={styles.blockedContainer}>
        <Link to="/blint" style={{ color: "white" }}>
          {" "}
          ← back to landing
        </Link>
        <h1>Access Denied</h1>
        <p>This page is not available in your country.</p>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Navbar />
      {displayMessage && displayMessage.length > 0 && (
        <BlintDisplayMessage
          messages={displayMessage}
          clearMessage={clearMessage}
        />
      )}
      {loading ? <LoadingBlorm /> : null}
      {showCongrats ? (
        <BlintCongrats
          txHash={successTxHash}
          tokenId={successTokenId}
          openseaURL={openseaURL}
          nft={nft}
          blintAgainClicked={blintAgainClicked}
          contractName={nftTitle}
        />
      ) : (
        <main className={styles.mainContainer}>
          <div className={styles.gridContainer}>
            <div className={styles.canvasContainer}>
              <div className={styles.canvasInner}>
                <canvas
                  ref={canvasRef}
                  width={2000}
                  height={2000}
                  className={styles.canvas}
                ></canvas>
              </div>
              <div className={styles.buttonWrapper}>
                {firstRender && (
                  <div className={styles.floatingArrow}>
                    <ArrowDown size={32} />
                  </div>
                )}
                <div className={styles.buttonsContainer}>
                  <button
                    className={`${styles.actionButton} ${
                      firstRender ? styles.shinyButton : ""
                    }`}
                    onClick={handleGenerate}
                  >
                    <img
                      src="/sparkle.svg"
                      alt="Sparkle"
                      className={`${styles.sparkle} ${
                        firstRender ? styles.blackSparkle : ""
                      }`}
                      width="28"
                      height="28"
                    />
                    <span>GENERATE</span>
                  </button>
                </div>
              </div>
            </div>
            <div className={styles.infoContainer}>
              <div className={styles.titleContainer}>
                <h2 className={styles.nftTitle}>{nftTitle}</h2>
                {publicFolder === "blormmy" && (
                  <a
                    href="https://x.com/blormmy"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.xLink}
                  >
                    <img
                      src={xIcon}
                      alt="Follow Blormmy on X"
                      className={styles.xIcon}
                    />
                  </a>
                )}
              </div>
              <div className={styles.mintContainer}>
                <div className={styles.quantityContainer}>
                  <label htmlFor="quantity" className={styles.quantityLabel}>
                    QUANTITY
                  </label>
                  <input
                    id="quantity"
                    type="number"
                    min="1"
                    value={quantity}
                    onChange={handleQuantityChange}
                    className={styles.quantityInput}
                  />
                </div>
                <div className={styles.priceContainer}>
                  <span>PRICE</span>
                  <span>{price} POL</span>
                </div>
                <button
                  className={`${styles.actionButton} ${styles.mintButton}`}
                  onClick={handleUploadAndMint}
                  disabled={!isCanvasValid || firstRender}
                >
                  <span>⟢ BLINT</span>
                </button>
              </div>
              <div className={styles.statsContainer}>
                <div className={styles.statsHeader}>
                  <BarChart className={styles.statsIcon} />
                  <h3 className={styles.statsTitle}>STATS</h3>
                </div>
                <div className={styles.statsContent}>
                  {isLoadingStats ? (
                    <p>Loading stats...</p>
                  ) : (
                    <>
                      <div className={styles.statItem}>
                        <span className={styles.statLabel}>
                          TOTAL VALUE LOCKED
                        </span>
                        <span className={styles.statValue}>
                          {totalValueLocked} USDC
                        </span>
                      </div>
                      <div className={styles.statItem}>
                        <span className={styles.statLabel}>TOTAL YIELD</span>
                        <span className={styles.statValue}>
                          {totalYield} USDC
                        </span>
                      </div>
                      <div className={styles.statItem}>
                        <span className={styles.statLabel}>TOTAL BLINTS</span>
                        <span className={styles.statValue}>{totalBlints}</span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.blormersContainer}>
            <h3 className={styles.blormersTitle}>TOP BLORMERS</h3>
            <div className={styles.blormersContent}>
              <table className={styles.blormersTable}>
                <thead>
                  <tr>
                    <th>RANK</th>
                    <th>ADDRESS</th>
                    <th>BLINTS</th>
                  </tr>
                </thead>
                <tbody>
                  {blormers.map((blormer, index) => (
                    <tr key={blormer.address}>
                      <td>{index + 1}</td>
                      <td>
                        <Link
                          to={`/user/${blormer.address}`}
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          {abbreviateAddress(blormer.address)}
                        </Link>
                      </td>
                      <td>{blormer.blints}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </main>
      )}
      {showModal && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <h2>Sign in to mint your NFT</h2>
            <button onClick={handleLogin} className={styles.actionButton}>
              Sign in
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Generator;

import React, { createContext, useContext, useState, useEffect } from 'react';
import { getAuth, setPersistence, browserLocalPersistence, signInWithCustomToken } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import Onboard from '@web3-onboard/core';
import walletConnectModule from '@web3-onboard/walletconnect';
import injectedModule from '@web3-onboard/injected-wallets';
import { auth, db } from '../utils/firebase';
import blintChains from '../utils/blintChains.json';

const wcInitOptions = {
  projectId: 'BLORM',
  requiredChains: [1],
  optionalChains: [42161, 8453, 10, 137, 56],
  dappUrl: 'https://blorm.xyz'
};

const injected = injectedModule();
const walletConnect = walletConnectModule(wcInitOptions);
const wallets = [injected];
const chains = Object.values(blintChains).map(chain => ({
  id: chain.id,
  token: chain.token,
  label: chain.label,
  rpcUrl: chain.rpcUrl,
}));

const onboard = Onboard({
  theme: 'system',
  wallets: [walletConnect, ...wallets],
  chains,
  accountCenter: {
    desktop: {
      enabled: false,
      position: 'topRight',
    },
    mobile: {
      enabled: false,
      position: 'topRight',
    }
  },
  connect: {
    autoConnectLastWallet: true
  }
});

const AuthContext = createContext({
  user: null,
  loading: true,
  error: null,
  walletAddress: null,
  profile: {
    name: '',
    bio: '',
    eth_address: '',
    sol_address: '',
    profilePicture: '',
  },
  wallet: null,
  setWalletAddress: () => {},
  signOut: () => {},
  handleLogin: () => {},
  fetchUserProfile: () => {},
  updateUserProfile: () => {},
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [walletAddress, setWalletAddress] = useState(null);
  const [wallet, setWallet] = useState(null);
  const [profile, setProfile] = useState({
    name: '',
    bio: '',
    eth_address: '',
    sol_address: '',
    profilePicture: '',
  });

  useEffect(() => {
    const auth = getAuth();
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
          // console.log('Auth state changed, user:', user);
          setLoading(true); // Set loading to true at the start of the state change
          if (user) {
            setUser(user);
            await reconnectWallet(user); // Attempt to reconnect wallet if user is logged in
          } else {
            setUser(null);
            resetProfileAndWallet();
          }
          setLoading(false); // Ensure loading state is updated after processing
        });
        return () => unsubscribe();
      })
      .catch((error) => {
        console.error('Error setting persistence:', error);
        setLoading(false);
      });
  }, []);

  const reconnectWallet = async (user) => {
    const savedWallet = localStorage.getItem('connectedWallet');
    // console.log('Saved wallet:', savedWallet);
    if (savedWallet) {
      const { wallets } = onboard.state.get();
      const previouslyConnectedWallets = wallets.map(wallet => wallet.accounts[0].address);
      setWallet(wallets[0]);
      // console.log('Previously connected wallets:', previouslyConnectedWallets);
      if (previouslyConnectedWallets.length > 0) {
        await onboard.connectWallet({
          autoSelect: { label: savedWallet, disableModals: true },
        });
        const walletAddress = previouslyConnectedWallets[0];
        setWalletAddress(walletAddress);

        const profileData = await fetchUserProfile(user.uid);
        if (profileData) {
          setProfile(profileData);
        }
      }
    }
  };

  const handleLogin = async () => {
    try {
      const [wallet] = await onboard.connectWallet();
      setWallet(wallet);
      if (wallet) {
        const walletAddress = wallet.accounts[0].address;
        setWalletAddress(walletAddress);
        localStorage.setItem('connectedWallet', wallet.label);

        const response = await fetch('https://us-central1-blorm-gcp.cloudfunctions.net/createCustomToken', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ walletAddress }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const { token } = await response.json();
        const userCredential = await signInWithCustomToken(auth, token);
        const uid = userCredential.user.uid;

        const userProfileRef = doc(db, 'profiles', uid);
        const userProfileSnap = await getDoc(userProfileRef);
        if (!userProfileSnap.exists()) {
          await setDoc(userProfileRef, {
            eth_address: uid,
            name: 'BLORMER',
            bio: 'BLORM INFORMATION ON CHAIN',
            sol_address: '',
            profilePicture: 'https://gateway.pinata.cloud/ipfs/QmY1HTnkpzJUFVZ9QWo1j51w88NZsV5ZpA32dtv4At9gki',
          });
        }

        const profileData = await fetchUserProfile(uid);
        if (profileData) {
          setProfile(profileData);
        }
      }
    } catch (error) {
      console.error('Error connecting wallet:', error);
      setError(error);
    }
  };

  const signOut = async () => {
    try {
      await auth.signOut();
      await onboard.disconnectWallet();
      localStorage.removeItem('connectedWallet');
    } catch (error) {
      console.error('Error signing out:', error);
    } finally {
      resetProfileAndWallet();
    }
  };

  const resetProfileAndWallet = () => {
    setWalletAddress(null);
    setUser(null);
    setProfile({
      name: '',
      bio: '',
      eth_address: '',
      sol_address: '',
      profilePicture: '',
    });
  };

  const fetchUserProfile = async (uid) => {
    const userProfileRef = doc(db, 'profiles', uid);
    const userProfileSnap = await getDoc(userProfileRef);
    if (userProfileSnap.exists()) {
      return userProfileSnap.data();
    } else {
      // console.log('No such document!');
      return null;
    }
  };

  const updateUserProfile = async (profileData) => {
    if (user) {
      const userProfileRef = doc(db, 'profiles', user.uid);
      await setDoc(userProfileRef, profileData, { merge: true });
      setProfile(profileData);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        error,
        walletAddress,
        profile,
        setWalletAddress,
        signOut,
        handleLogin,
        fetchUserProfile,
        updateUserProfile,
        wallet
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

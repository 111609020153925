import React, { useLayoutEffect, useState, useRef, useEffect, useCallback } from 'react';
import BlintDisplayMessage from '../../components/BlintDisplayMessage.js';
import styles from '../../styles/Rayquaza.module.css';
import UploadToIPFS from '../../utils/UploadToIPFS.js';
import { mintTokenWithWallet } from '../../utils/mintToken.js';
import BlintCongrats from '../../components/BlintCongrats.js';
import ChainDropdown from '../../components/ChainDropdown.js';
import Navbar from '../../components/Navbar.js';
import { useAuth } from '../../context/AuthContext.js';
import LoadingBlorm from '../../components/LoadingBlorm.js';
import { debounce } from 'lodash';
import manifest from '../../utils/manifestRayquaza.json';
import { ethers } from 'ethers';
import { BarChart } from 'lucide-react';
import blintCollections from '../../utils/blintCollections.json';
import { Link } from 'react-router-dom';

const layers = [
    { id: 'layer0', label: 'Layer 1', folder: '1' },
    { id: 'layer1', label: 'Layer 2', folder: '2', blend: 'overlay' }
];

const getRandomImage = (folder) => {
    const images = manifest[folder];
    if (!images || images.length === 0) {
        console.error(`No images found for folder: ${folder}`);
        return '';
    }
    const randomIndex = Math.floor(Math.random() * images.length);
    const encodedImagePath = encodeURIComponent(images[randomIndex]);
    return `/rayquaza/${folder}/${encodedImagePath}`;
};

const hexToRgb = (hex) => {
    const bigint = parseInt(hex.slice(1), 16);
    return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
};

const clamp = (value, min, max) => Math.min(Math.max(value, min), max);

const getRandomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

const sampleRandomShade = (rgb) => {
    return [
        clamp(rgb[0] + getRandomInt(-10, 10), 0, 255),
        clamp(rgb[1] + getRandomInt(-10, 10), 0, 255),
        clamp(rgb[2] + getRandomInt(-10, 10), 0, 255)
    ];
};

const rgbArrayToHex = (rgb) => {
    return `#${rgb.map(x => x.toString(16).padStart(2, '0')).join('')}`;
};

const Rayquaza = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [colorShift, setColorShift] = useState(false);
    const [bannerSize, setBannerSize] = useState('5vw');
    const [marginSize, setMarginSize] = useState('.35rem');
    const [bannerRows, setBannerRows] = useState(2);
    const { user, walletAddress, profile, handleLogin, wallet } = useAuth();
    const [showModal, setShowModal] = useState(false);
    const [displayMessage, setDisplayMessage] = useState([]);
    const [showCongrats, setShowCongrats] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tokenUrl, setTokenUrl] = useState('');
    const [layerColors, setLayerColors] = useState({});
    const canvasRef = useRef(null);
    const [canvasDataURL, setCanvasDataURL] = useState('');
    const [uploadUrl, setUploadUrl] = useState('');
    const [checkResult, setCheckResult] = useState('');
    const [addResult, setAddResult] = useState('');
    const [firstRender, setFirstRender] = useState(true);
    const [starCount, setStarCount] = useState(0);
    const [recipientAddress, setRecipientAddress] = useState('');
    const [metadata, setMetadata] = useState({});
    const [successTxHash, setSuccessTxHash] = useState('');
    const [successTokenId, setSuccessTokenId] = useState('');
    const [openseaURL, setOpenseaURL] = useState('');
    const [nft, setNft] = useState(null);
    const [isCanvasValid, setIsCanvasValid] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [price, setPrice] = useState(2);
    const [nftTitle, setNftTitle] = useState('BLORMQUAZA');
    const [contractAddress, setContractAddress] = useState('');
    const [totalValueLocked, setTotalValueLocked] = useState('0');
    const [totalYield, setTotalYield] = useState('0');
    const [totalBlints, setTotalBlints] = useState(0);
    const [isLoadingStats, setIsLoadingStats] = useState(true);
    // Geoblocking State Variables
    const [isBlocked, setIsBlocked] = useState(false);
    const [isLoadingGeo, setIsLoadingGeo] = useState(true);

    // **Geoblocking Effect**
    useEffect(() => {
        const fetchGeolocation = async () => {
            try {
                const response = await fetch('https://ipapi.co/json/');
                if (!response.ok) {
                    throw new Error(`Geolocation API error: ${response.statusText}`);
                }
                const data = await response.json();
                const userCountry = data.country;

                if (userCountry === 'US') {  // Block access from the US
                    setIsBlocked(true);
                }
            } catch (error) {
                console.error('Error fetching geolocation data:', error);
                // Optionally, you can choose to block access if geolocation fails
                // setIsBlocked(true);
            } finally {
                setIsLoadingGeo(false);
            }
        };

        fetchGeolocation();
    }, []);

    useEffect(() => {
        const blormCollection = blintCollections.find(collection => collection.title === 'BLORMQUAZA');
        if (blormCollection) {
            setNftTitle(blormCollection.title);
            setContractAddress(blormCollection.contractAddress);
        }
    }, []);

    const fetchContractData = async () => {
        setIsLoadingStats(true);
        const provider = new ethers.BrowserProvider(window.ethereum);
        const network = await provider.getNetwork();

        if (network.chainId !== 137) {
            try {
                await provider.send("wallet_switchEthereumChain", [{ chainId: "0x89" }]);
            } catch (switchError) {
                if (switchError.code === 4902) {
                    await provider.send("wallet_addEthereumChain", [
                        {
                            chainId: "0x89",
                            chainName: "Polygon Mainnet",
                            rpcUrls: ["https://polygon-rpc.com/"],
                            nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
                            blockExplorerUrls: ["https://polygonscan.com/"]
                        }
                    ]);
                } else {
                    console.log(switchError);
                }
            }
        }

        const abi = [
            "function getTotalValueLocked() external view returns (uint256)",
            "function getTotalYield() external view returns (uint256)",
            "function nextMintId() public view returns (uint256)"
        ];

        try {
            const contract = new ethers.Contract(contractAddress, abi, provider);

            const tvl = await contract.getTotalValueLocked();
            const yield_ = await contract.getTotalYield();
            const nextMint = await contract.nextMintId();
            const blints = parseInt(nextMint) - 1;

            setTotalValueLocked(ethers.formatUnits(tvl.toString(), 6));
            setTotalYield(ethers.formatUnits(yield_.toString(), 6));
            setTotalBlints(blints);
        } catch (error) {
            console.error('Error fetching contract data:', error);
        }
        setIsLoadingStats(false);
    };

    useEffect(() => {
        fetchContractData();
    }, [contractAddress]);

    const checkIfMobile = useCallback(() => {
        if (typeof window !== 'undefined') {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            const isMobileDevice = /android|iphone|ipad|iPod|opera mini|iemobile|wpdesktop/i.test(userAgent) ||
                window.innerWidth <= 768;
            setIsMobile(isMobileDevice);
        }
    }, []);

    useEffect(() => {
        checkIfMobile();
        window.addEventListener('resize', checkIfMobile);
        return () => window.removeEventListener('resize', checkIfMobile);
    }, [checkIfMobile]);

    useLayoutEffect(() => {
        const canvas = canvasRef.current;
        if (canvas) {
            if (isMobile) {
                canvas.width = 1600;
                canvas.height = 1600;
            } else {
                canvas.width = 1600;
                canvas.height = 1600;
            }
        }
        resizeBanner();
    }, [isMobile]);

    const resizeBanner = useCallback(() => {
        const isMobileDevice = window.innerWidth <= 768 || window.innerHeight <= 500;
        setBannerSize(isMobileDevice ? '12.5vw' : '5vw');
        setMarginSize(isMobileDevice ? '.35rem' : '.35rem');
    }, []);

    const generateLayerColors = () => {
        const layerColors = {};
        const usedColors = new Set();

        const generateUniqueColor = () => {
            let color;
            do {
                color = generateRandomColor();
            } while (usedColors.has(color));
            usedColors.add(color);
            return color;
        };

        layerColors.layer0 = generateUniqueColor();

        for (let i = 1; i < layers.length; i++) {
            if (layers[i].id !== 'logo') {
                layerColors[`layer${i}`] = generateUniqueColor();
            }
        }

        return layerColors;
    };

    const generateRandomColor = () => {
        const r = getRandomInt(0, 255);
        const g = getRandomInt(0, 255);
        const b = getRandomInt(0, 255);
        return rgbArrayToHex([r, g, b]);
    };

    useEffect(() => {
        renderCanvas();
    }, []);

    const renderCanvas = useCallback(
        debounce(async () => {
            const canvas = canvasRef.current;
            if (canvas) {
                const ctx = canvas.getContext('2d');

                // Set canvas dimensions
                canvas.width = 1600;
                canvas.height = 1600;

                // Fill the canvas with white
                ctx.fillStyle = 'white';
                ctx.fillRect(0, 0, canvas.width, canvas.height);

                // Load and draw the logo
                const logoImage = await loadImage('/logo2.png');
                const logoSize = 80;
                const logoX = (canvas.width - logoSize) - 20;
                const logoY = (canvas.height - logoSize) - 20;
                ctx.drawImage(logoImage, logoX, logoY, logoSize, logoSize);

                setCanvasDataURL(canvas.toDataURL('image/png'));
                setIsCanvasValid(true);
            }
        }, 300),
        []);

    const loadImage = (src) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = 'anonymous';
            img.onload = () => resolve(img);
            img.onerror = () => {
                console.error(`Failed to load image: ${src}`);
                reject(new Error(`Failed to load image: ${src}`));
            };
            img.src = src;
        });
    };

    const isCanvasEmpty = (canvas) => {
        const ctx = canvas.getContext('2d');
        const pixelBuffer = new Uint32Array(
            ctx.getImageData(0, 0, canvas.width, canvas.height).data.buffer
        );
        return !pixelBuffer.some(color => color !== 0);
    };

    const rgbToHex = (r, g, b) => {
        const toHex = (value) => {
            const hex = value.toString(16);
            return hex.length === 1 ? `0${hex}` : hex;
        };
        return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
    };

    const adjustColor = (color, amount) => {
        return Math.max(Math.min(color + amount, 255), 0);
    };

    const generateGradientColors = (baseColor) => {
        const rgb = hexToRgb(baseColor);
        const primaryColor = rgbToHex(
            adjustColor(rgb[0], 20),
            adjustColor(rgb[1], 20),
            adjustColor(rgb[2], 20)
        );
        const secondaryColor = rgbToHex(
            adjustColor(rgb[0], -20),
            adjustColor(rgb[1], -20),
            adjustColor(rgb[2], -20)
        );
        return { primaryColor, secondaryColor };
    };

    const applyGradientMap = (imageData, primaryColor, secondaryColor) => {
        const data = imageData.data;
        const primary = hexToRgb(primaryColor);
        const secondary = hexToRgb(secondaryColor);

        for (let i = 0; i < data.length; i += 4) {
            const alpha = data[i + 3];
            if (alpha === 0) continue;

            const grayscale = data[i] * 0.3 + data[i + 1] * 0.59 + data[i + 2] * 0.11;
            const t = grayscale / 255;

            data[i] = Math.round(primary[0] * (1 - t) + secondary[0] * t);
            data[i + 1] = Math.round(primary[1] * (1 - t) + secondary[1] * t);
            data[i + 2] = Math.round(primary[2] * (1 - t) + secondary[2] * t);
        }

        return imageData;
    };

    const shiftColors = (ctx, canvas) => {
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;

        for (let i = 0; i < data.length; i += 4) {
            const r = data[i];
            const g = data[i + 1];
            const b = data[i + 2];

            if (!((r < 10 && g < 10 && b < 10) || (r > 245 && g > 245 && b > 245))) {
                const shiftAmount1 = Math.floor(Math.random() * 50) - 25;
                const shiftAmount2 = Math.floor(Math.random() * 50) - 25;
                const shiftAmount3 = Math.floor(Math.random() * 50) - 25;

                data[i] = clamp(r + shiftAmount1, 0, 255);
                data[i + 1] = clamp(g + shiftAmount2, 0, 255);
                data[i + 2] = clamp(b + shiftAmount3, 0, 255);
            }
        }

        ctx.putImageData(imageData, 0, 0);
    };

    const drawStars = (ctx) => {
        const count = getRandomInt(1, 5);
        setStarCount(count);
        const starImage = new Image();
        starImage.src = '/whitestar2.png';
        starImage.onload = () => {
            for (let i = 0; i < count; i++) {
                const x = getRandomInt(0, ctx.canvas.width);
                const y = getRandomInt(0, ctx.canvas.height);
                const angle = getRandomInt(0, 360);
                const size = getRandomInt(50, 100);
                ctx.save();
                ctx.translate(x, y);
                ctx.rotate((angle * Math.PI) / 180);
                ctx.drawImage(starImage, -size / 2, -size / 2, size, size);
                ctx.restore();
            }
        };
    };

    const clearCanvas = (ctx) => {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    };

    const renderLayers = async (ctx, newLayerImages) => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        const offscreenCanvas = document.createElement('canvas');
        const offscreenCtx = offscreenCanvas.getContext('2d');

        offscreenCanvas.width = 1851;
        offscreenCanvas.height = 1810;

        try {
            const layerImages = await Promise.all(layers.map(layer => loadImage(newLayerImages[layer.id])));

            for (let i = 0; i < layers.length; i++) {
                const img = layerImages[i];
                offscreenCtx.globalCompositeOperation = layers[i].blend || 'source-over';
                offscreenCtx.drawImage(img, 0, 0, 1851, 1810);
            }

            ctx.imageSmoothingEnabled = false;
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(offscreenCanvas, 0, 0, 1851, 1810, 0, 0, canvas.width, canvas.height);

            ctx.fillStyle = 'white';
            ctx.fillRect(0, 0, 10, canvas.height);
            ctx.fillRect(canvas.width - 10, 0, 10, canvas.height);

            const logoImage = await loadImage('/logo2.png');
            ctx.globalAlpha = 0.4;
            const logoSize = 80;
            const logoX = canvas.width - logoSize - 20;
            const logoY = canvas.height - logoSize - 20;
            ctx.drawImage(logoImage, logoX, logoY, logoSize, logoSize);

            ctx.globalAlpha = 1;

            setCanvasDataURL(canvas.toDataURL('image/png'));
        } catch (error) {
            console.error("Error rendering layers:", error);
        }
    };

    const updateMetadata = () => {
        const newMetadata = {
            description: `Blorm everything.`,
            image: '',
            creator: 'BLORM',
            motto: 'Blorm everything.',
            collection: nftTitle,
            external_url: 'https://blorm.xyz'
        };
        setMetadata(newMetadata);
    };

    useEffect(() => {
        updateMetadata();
    }, [layerColors, starCount]);

    const clearMessage = (index) => {
        setDisplayMessage((prevMessages) => prevMessages.filter((_, i) => i !== index));
    };

    const handleUploadAndMint = async () => {
        if (!user || !walletAddress || !profile) {
            setShowModal(true);
            return;
        }

        try {


            setLoading(true);
            setTokenUrl('Loading...');

            const uri = await UploadToIPFS(canvasDataURL);
            const updatedMetadata = { ...metadata, image: uri };
            setMetadata(updatedMetadata);

            const txResponse = await mintTokenWithWallet(updatedMetadata, contractAddress, wallet, quantity);
            const tokenId = txResponse[0];
            const txHash = txResponse[1];
            setSuccessTxHash(txHash);
            if (tokenId === undefined) {
                setDisplayMessage([...displayMessage, { message: 'Failed to get token ID.', type: 'error' }]);
                return;
            }
            setSuccessTokenId(tokenId);

            setLoading(false);
            setNft({ metadata: updatedMetadata, tokenId, chain: 'Polygon', chainId: '137' });
            setShowCongrats(true);
        } catch (error) {
            setLoading(false);
            setDisplayMessage([...displayMessage, { message: 'There was an issue with uploading and minting. Please ensure you have sufficient funds and try again.', type: 'error' }]);
        }
    };

    const handleClose = () => {
        setDisplayMessage([]);
    };

    useEffect(() => {
        if (user && showModal) {
            setShowModal(false);
        }
    }, [user, profile, walletAddress]);

    const blintAgainClicked = () => {
        setShowCongrats(false);
        setCanvasDataURL('');
        setSuccessTxHash('');
        setSuccessTokenId('');
        setOpenseaURL('');
        setNft(null);
        setStarCount(0);
        setRecipientAddress('');
        setLayerColors({});
        setMetadata({});
        setTokenUrl('');
        setUploadUrl('');
        setCheckResult('');
        setAddResult('');
        setCanvasDataURL('');
        setDisplayMessage([]);
        setFirstRender(true);
        setQuantity(1);
        renderCanvas();
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (event.target.className.includes('modal')) {
                setShowModal(false);
            }
        };

        if (showModal) {
            window.addEventListener('click', handleOutsideClick);
        } else {
            window.removeEventListener('click', handleOutsideClick);
        }

        return () => {
            window.removeEventListener('click', handleOutsideClick);
        };
    }, [showModal]);

    const handleGenerate = useCallback(
        debounce(async () => {
            setFirstRender(false);

            const canvas = canvasRef.current;
            if (canvas) {
                const ctx = canvas.getContext('2d');
                await renderLayers(ctx, { layer0: getRandomImage('1'), layer1: getRandomImage('2') });
                const isEmpty = isCanvasEmpty(canvas);
                setIsCanvasValid(!isEmpty);
                if (!isEmpty) {
                    setCanvasDataURL(canvas.toDataURL('image/png'));
                } else {
                    setCanvasDataURL('');
                }
            }
        }, 300),
        []
    );

    const handleQuantityChange = (event) => {
        const value = parseInt(event.target.value, 10);
        const newQuantity = isNaN(value) ? 1 : Math.max(1, value);
        setQuantity(newQuantity);
        setPrice(newQuantity * 2);
    };

    useEffect(() => {
        setPrice(quantity * 2);
    }, [quantity]);

    const [blormers, setBlormers] = useState([]);

    const fetchAllOwnedTokens = async () => {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const network = await provider.getNetwork();

        if (network.chainId !== 137) {
            try {
                await provider.send("wallet_switchEthereumChain", [{ chainId: "0x89" }]);
            } catch (switchError) {
                if (switchError.code === 4902) {
                    await provider.send("wallet_addEthereumChain", [
                        {
                            chainId: "0x89",
                            chainName: "Polygon Mainnet",
                            rpcUrls: ["https://polygon-rpc.com/"],
                            nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
                            blockExplorerUrls: ["https://polygonscan.com/"]
                        }
                    ]);
                } else {
                    console.log(switchError);
                }
            }
        }
        const abi = [
            "function getAllOwnedTokens() external view returns (address[] memory, uint256[][] memory)"
        ];

        try {
            const contract = new ethers.Contract(contractAddress, abi, provider);
            const [owners, tokens] = await contract.getAllOwnedTokens();

            const blormersData = owners.map((owner, index) => ({
                address: owner,
                blints: tokens[index].length
            }));

            blormersData.sort((a, b) => b.blints - a.blints);

            setBlormers(blormersData);
        } catch (error) {
            console.error('Error fetching all owned tokens:', error);
        }
    };

    useEffect(() => {
        fetchContractData();
        fetchAllOwnedTokens();
    }, [contractAddress]);

    // **Conditional Rendering Based on Geoblocking**
    if (isLoadingGeo) {
        return (
            <div className={styles.loadingContainer}>
                <LoadingBlorm />
            </div>
        );
    }

    if (isBlocked) {
        return (
            <div className={styles.blockedContainer}>
                <Link to="/blint" style={{ color: 'white' }}> ← back to landing</Link>
                <h1>Access Denied</h1>
                <p>This page is not available in your country.</p>
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <Navbar />
            {displayMessage && displayMessage.length > 0 && (
                <BlintDisplayMessage messages={displayMessage} clearMessage={clearMessage} />
            )}
            {loading ? <LoadingBlorm /> : null}
            {showCongrats ? (
                <BlintCongrats
                    txHash={successTxHash}
                    tokenId={successTokenId}
                    openseaURL={openseaURL}
                    nft={nft}
                    blintAgainClicked={blintAgainClicked}
                />
            ) : (
                <main className={styles.mainContainer}>
                    <div className={styles.gridContainer}>
                        <div className={styles.canvasContainer}>
                            <div className={styles.canvasInner}>
                                <canvas ref={canvasRef} width={1600} height={1600} className={styles.canvas}></canvas>
                            </div>
                            <div className={styles.buttonsContainer}>
                                <button
                                    className={`${styles.actionButton} ${firstRender ? styles.shinyButton : ''}`}
                                    onClick={handleGenerate}
                                >
                                    <img
                                        src="/sparkle.svg"
                                        alt="Sparkle"
                                        className={`${styles.sparkle} ${firstRender ? styles.blackSparkle : ''}`}
                                        width="28"
                                        height="28"
                                    />
                                    GENERATE
                                </button>
                            </div>
                        </div>
                        <div className={styles.infoContainer}>
                            <h2 className={styles.nftTitle}>{nftTitle}</h2>
                            <div className={styles.mintContainer}>
                                <div className={styles.quantityContainer}>
                                    <label htmlFor="quantity" className={styles.quantityLabel}>
                                        QUANTITY
                                    </label>
                                    <input
                                        id="quantity"
                                        type="number"
                                        min="1"
                                        value={quantity}
                                        onChange={handleQuantityChange}
                                        className={styles.quantityInput}
                                    />
                                </div>
                                <div className={styles.priceContainer}>
                                    <span>PRICE</span>
                                    <span>{price} POL</span>
                                </div>
                                <button
                                    className={`${styles.actionButton} ${styles.mintButton}`}
                                    onClick={handleUploadAndMint}
                                    disabled={!isCanvasValid || firstRender}
                                >
                                    <img src="/chain.svg" alt="chain" className={styles.sparkle} width="20" height="20" />
                                    <span>MINT</span>
                                </button>
                            </div>
                            <div className={styles.statsContainer}>
                                <div className={styles.statsHeader}>
                                    <BarChart className={styles.statsIcon} />
                                    <h3 className={styles.statsTitle}>STATS</h3>
                                </div>
                                <div className={styles.statsContent}>
                                    {isLoadingStats ? (
                                        <p>Loading stats...</p>
                                    ) : (
                                        <>
                                            <div className={styles.statItem}>
                                                <span className={styles.statLabel}>TOTAL VALUE LOCKED</span>
                                                <span className={styles.statValue}>{totalValueLocked} USDC</span>
                                            </div>
                                            <div className={styles.statItem}>
                                                <span className={styles.statLabel}>TOTAL YIELD</span>
                                                <span className={styles.statValue}>{totalYield} USDC</span>
                                            </div>
                                            <div className={styles.statItem}>
                                                <span className={styles.statLabel}>TOTAL BLINTS</span>
                                                <span className={styles.statValue}>{totalBlints}</span>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.blormersContainer}>
                        <h3 className={styles.blormersTitle}>BLORMERS</h3>
                        <div className={styles.blormersContent}>
                            <table className={styles.blormersTable}>
                                <thead>
                                    <tr>
                                        <th>RANK</th>
                                        <th>ADDRESS</th>
                                        <th>BLINTS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {blormers.map((blormer, index) => (
                                        <tr key={blormer.address}>
                                            <td>{index + 1}</td>
                                            <td>{blormer.address}</td>
                                            <td>{blormer.blints}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </main>
            )}
            {showModal && (
                <div className={styles.modal}>
                    <div className={styles.modalContent}>
                        <h2>Sign in to mint your NFT</h2>
                        <button onClick={handleLogin} className={styles.actionButton}>Sign in</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Rayquaza;

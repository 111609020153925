import React from "react";
import styles from "../styles/BlintCongrats.module.css";
import logo from "../images/logo.png";
import tg from "../images/tg.png";
import { Link } from "react-router-dom";
import { ArrowRightCircle, Send } from "lucide-react";
import polygonLogo from "../images/polygon.png";

const BlintCongrats = ({
  txHash,
  tokenId,
  openseaURL,
  nft,
  blintAgainClicked,
  contractName,
}) => {
  if (!nft) {
    return (
      <div className={styles.container}>
        <div className={styles.centerContainer}>
          <div className={styles.noNfts}>
            <p>Error loading congratulations page.</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.centerContainer}>
        <div className={styles.leftPanel}>
          <div className={styles.nftCard}>
            <img
              src={nft.metadata.image}
              alt={nft.metadata.name}
              className={styles.nftCardImage}
            />
            <div className={styles.nftDetails}>
              <div className={styles.nftField}>
                <strong>COLLECTION:</strong> {contractName}
              </div>
              <div className={styles.nftField}>
                <strong>CHAIN:</strong>{" "}
                <div>
                  Polygon{" "}
                  <img
                    src={polygonLogo}
                    alt="Polygon"
                    className={styles.chainIcon}
                  />
                </div>
              </div>
              <div className={styles.nftField}>
                <strong>TOKEN ID:</strong> {tokenId}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.rightPanel}>
          <h1 className={styles.title}>Blinting Complete</h1>
          <p className={styles.subtitle}>
            Your NFT is now earning you rewards. <br />
            <br />
            Blorm did all the heavy lifting for you because Blorm cares for you.{" "}
            <br />
            <br />
            Join your{" "}
            <a
              href="https://t.me/+XsidUbJTqkA3M2Qx"
              target="_blank"
              rel="noopener noreferrer"
            >
              community
            </a>{" "}
            to connect with fellow holders.
          </p>
          <div className={styles.callToAction}>
            <Link className={styles.actionButtonContainer} to="/profile">
              <button className={styles.actionButton}>
                My Profile <ArrowRightCircle size={18} />
              </button>
            </Link>
            <button className={styles.actionButton} onClick={blintAgainClicked}>
              Blint Again
            </button>
            <a
              href="https://t.me/+XsidUbJTqkA3M2Qx"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.actionButtonContainer}
            >
              <Send size={18} className={styles.telegramIcon} />
            </a>
            <img src={logo} className={styles.callToActionLogo} alt="logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlintCongrats;

import React, { useState, useEffect, useRef } from "react";
import OpenAI from "openai";
import { zodResponseFormat } from "openai/helpers/zod";
import { z } from "zod";
import styles from "../styles/Blormmy-Brain.module.css";
import { ethers } from "ethers";
import { useAuth } from "../context/AuthContext";
import Navbar from "../components/Navbar";
import {
  MAINNET_TOKENS_BY_SYMBOL,
  PERMIT2_ADDRESS,
  AFFILIATE_FEE,
  FEE_RECIPIENT,
} from "../utils/swapTokens";
import erc20Abi from "../utils/erc20.json";
import { concat, numberToHex, size } from "viem";

const polygonChainParams = {
  chainId: "0x89", // Polygon Mainnet    s
  chainName: "Polygon Mainnet",
  nativeCurrency: {
    name: "MATIC",
    symbol: "MATIC",
    decimals: 18,
  },
  rpcUrls: ["https://polygon-rpc.com"],
  blockExplorerUrls: ["https://polygonscan.com/"],
};

// Initialize OpenAI with your API key
const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true, // Replace with your OpenAI API key
});

// Define the JSON schema for send details using Zod
const sendNativeSchema = z.object({
  amount: z.number(),
  recipient: z.string(),
});

// Define the JSON schema for send details using Zod
const sendTokenSchema = z.object({
  amount: z.number(),
  recipient: z.string(),
  tokenSymbol: z.string().optional(),
});

// Define the JSON schema for swap details using Zod
const swapSchema = z.object({
  tokenIn: z.string(),
  tokenOut: z.string(),
  amount: z.number(),
});

// Define the JSON schema for deploy erc-20 details using Zod
const deployErc20Schema = z.object({
  tokenName: z.string(),
  tokenSymbol: z.string(),
  tokenDecimals: z.number(),
  tokenSupply: z.number(),
});

// Define the JSON schema for structured responses using Zod
const ResponseSchema = z.object({
  message: z.string(),
  action: z.enum([
    "none",
    "swap",
    "send (native)",
    "send (token)",
    "deploy erc-20",
  ]),
  swapDetails: swapSchema.optional(),
  sendNativeDetails: sendNativeSchema.optional(),
  sendTokenDetails: sendTokenSchema.optional(),
  deployErc20Details: deployErc20Schema.optional(),
});

// Modal Component for Polygon Network Switch
const PolygonSwitchComponent = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.polygonModalOverlay}>
      <div className={styles.polygonModalContent}>
        <h2>Switch to Polygon</h2>
        <p>Please switch to the Polygon network to continue.</p>
        <button onClick={onConfirm} className={styles.polygonButton}>
          Switch Network
        </button>
        <button onClick={onClose} className={styles.polygonButton}>
          Cancel
        </button>
      </div>
    </div>
  );
};

// Main App Component
export default function BlormmyBrain() {
  const { user, walletAddress, wallet } = useAuth();
  const [messages, setMessages] = useState([
    {
      sender: "system",
      content: `You are a professional blockchain intents abstraction layer named Blormmy's Brain. 
            You can perform the following actions: none, send (native), send (token), send swap.
            You can send (native) MATIC or POL.
            You can send (token) WETH, USDC, DAI, USDT, and WMATIC.
            You can swap WETH, USDC, DAI, USDT, and WMATIC.
            You can deploy erc-20 tokens.
            You are to find a user's intent and you must determine the correct onchain action to perform in your response. 
            You must also provide the parameters for that action. 
            Chat with the user and collect the information you need to perform the action. 
            If the user's intent or parameters are not clear, ask them to clarify. 
            If the user's intent is to send (native), provide the amount and recipient. 
            If the user's intent is to send (token), provide the amount, recipient, and token symbol.
            If the user's intent is to swap, provide the tokenIn, tokenOut, and amount. 
            If the user's intent is to deploy erc-20, provide the tokenName, tokenSymbol, tokenDecimals, and tokenSupply.
            ONLY if you have an action and ALL its parameters, return an action other than none if the chosen action is available.
            `,
    },
    {
      sender: "assistant",
      content: `
      <div class="${styles.messageContent}">
          <img src="/blint-landing/blormmy.png" alt="Blormmy" />
          <p>Hello! I am Blormmy's Brain, your blockchain intents abstraction layer. 
          I can help you with the following actions:
          - Send native currency (MATIC / POL)
          - Send tokens (WETH, USDC, DAI, USDT, WMATIC)
          - Swap tokens (WETH, USDC, DAI, USDT, WMATIC)
          Please let me know what you would like to do.</p>
      </div>
      `,
    },
  ]);
  const [input, setInput] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const chatBoxRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const checkNetwork = async () => {
      if (window.ethereum) {
        const chainId = await window.ethereum.request({
          method: "eth_chainId",
        });
        if (chainId !== polygonChainParams.chainId) {
          setIsModalOpen(true);
        }
      }
    };
    checkNetwork();
  }, []);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSwitchNetwork = async () => {
    if (window.ethereum === undefined) {
      console.error("No Ethereum provider found.");
      return;
    }
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: polygonChainParams.chainId }],
      });
      setIsModalOpen(false);
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [polygonChainParams],
          });
          setIsModalOpen(false);
        } catch (addError) {
          console.error(
            "Failed to add the Polygon chain to the wallet",
            addError
          );
        }
      } else {
        console.error("Failed to switch to the Polygon chain", switchError);
      }
    }
  };

  // Function to handle sending messages
  const handleSend = async () => {
    if (input.trim() === "") return;

    // Append user's message to the conversation
    const userMessage = { sender: "user", content: input };
    setMessages((prev) => [...prev, userMessage]);
    setInput("");
    setIsLoading(true);

    try {
      // Add loading message
      setMessages((prev) => [
        ...prev,
        {
          sender: "assistant",
          content: `
        <div class="${styles.messageContent}">
          <img src="/blint-landing/blormmy.png" alt="Blormmy" />
          <p><em>Thinking...</em></p>
        </div>
        `,
        },
      ]);

      // Prepare messages for OpenAI API
      const conversation = [
        {
          role: "system",
          content: `You are a professional blockchain intents abstraction layer named Blormmy Brain. 
            You can perform the following actions: none, send (native), send (token), send swap.
            You can send (native) MATIC or POL.
            You can send (token) WETH, USDC, DAI, USDT, and WMATIC.
            You can swap WETH, USDC, DAI, USDT, and WMATIC.
            You can deploy erc-20 tokens.
            You are to find a user's intent and you must determine the correct onchain action to perform in your response. 
            You must also provide the parameters for that action. 
            Chat with the user and collect the information you need to perform the action. 
            If the user's intent or parameters are not clear, ask them to clarify. 
            If the user's intent is to send (native), provide the amount and recipient. 
            If the user's intent is to send (token), provide the amount, recipient, and token symbol.
            If the user's intent is to swap, provide the tokenIn, tokenOut, and amount. 
            If the user's intent is to deploy erc-20, provide the tokenName, tokenSymbol, tokenDecimals, and tokenSupply.
            ONLY if you have an action and ALL its parameters, return an action other than none if the chosen action is available.
            `,
        },
        ...messages
          .filter((msg) => msg.sender !== "system")
          .map((msg) => ({
            role: msg.sender === "user" ? "user" : "assistant",
            content: msg.content,
          })),
        { role: "user", content: input },
      ];

      // Make API call with structured response using Zod
      const completion = await openai.beta.chat.completions.parse({
        model: "gpt-4o-2024-08-06",
        messages: conversation,
        response_format: zodResponseFormat(
          ResponseSchema,
          "structured_response"
        ),
      });

      const response = completion.choices[0].message.parsed;

      // Remove loading message and handle response
      if (response.action === "none") {
        setMessages((prev) => [
          ...prev.slice(0, -1),
          {
            sender: "assistant",
            content: `
            <div class="${styles.messageContent}">
              <img src="/blint-landing/blormmy.png" alt="Blormmy" />
              <p>${response.message}</p>
            </div>
            `,
          },
        ]);
      } else if (response.action === "swap") {
        const { tokenIn, tokenOut, amount } = response.swapDetails;

        if (tokenIn && tokenOut && amount) {
          // All swap details provided
          setMessages((prev) => [
            ...prev.slice(0, -1),
            {
              sender: "assistant",
              content: `
              <div class="${styles.messageContent}">
                <img src="/blint-landing/blormmy.png" alt="Blormmy" />
                <p>Initiating swap: ${amount} ${tokenIn} to ${tokenOut}.</p>
              </div>
              `,
            },
          ]);
          await handleSwap(tokenIn, tokenOut, amount);
        } else {
          // Incomplete swap details, ask for more information
          setMessages((prev) => [
            ...prev.slice(0, -1),
            {
              sender: "assistant",
              content: `
              <div class="${styles.messageContent}">
                <img src="/blint-landing/blormmy.png" alt="Blormmy" />
                <p>Initiating swap: ${amount} ${tokenIn} to ${tokenOut}.</p>
              </div>
              `,
            },
          ]);
        }
      } else if (response.action === "send (native)") {
        const { amount, recipient } = response.sendNativeDetails;

        if (amount && recipient) {
          // All send details provided
          setMessages((prev) => [
            ...prev.slice(0, -1),
            {
              sender: "assistant",
              content: `
              <div class="${styles.messageContent}">
                <img src="/blint-landing/blormmy.png" alt="Blormmy" />
                <p>Initiating send: ${amount} to ${recipient}.</p>
              </div>
              `,
            },
          ]);

          // Implement send logic using ethers
          try {
            const provider = new ethers.BrowserProvider(wallet.provider);
            const signer = await provider.getSigner();
            const tx = await signer.sendTransaction({
              to: recipient,
              value: ethers.parseEther(amount.toString()),
            });
            console.log("Transaction sent:", tx);
          } catch (sendError) {
            console.error("Error sending transaction:", sendError);
            setMessages((prev) => [
              ...prev.slice(0, -1),
              {
                sender: "assistant",
                content: `
                <div class="${styles.messageContent}">
                  <img src="/blint-landing/blormmy.png" alt="Blormmy" />
                  <p>Failed to send transaction.</p>
                </div>
                `,
              },
            ]);
          }
        } else {
          // Incomplete send details, ask for more information
          setMessages((prev) => [
            ...prev.slice(0, -1),
            {
              sender: "assistant",
              content: `
              <div class="${styles.messageContent}">
                <img src="/blint-landing/blormmy.png" alt="Blormmy" />
                <p>Failed to send transaction.</p>
              </div>
              `,
            },
          ]);
        }
      } else if (response.action === "send (token)") {
        const { amount, recipient, tokenSymbol } = response.sendTokenDetails;

        if (amount && recipient && tokenSymbol) {
          console.log(tokenSymbol);
          // All send details provided
          setMessages((prev) => [
            ...prev.slice(0, -1),
            {
              sender: "assistant",
              content: `
              <div class="${styles.messageContent}">
                <img src="/blint-landing/blormmy.png" alt="Blormmy" />
                <p>Initiating send: ${amount} ${tokenSymbol} to ${recipient}.</p>
              </div>
              `,
            },
          ]);

          // Implement send token logic using ethers
          try {
            const provider = new ethers.BrowserProvider(wallet.provider);
            const signer = await provider.getSigner();
            let tokenContract;

            if (
              tokenSymbol &&
              MAINNET_TOKENS_BY_SYMBOL[tokenSymbol.toLowerCase()]
            ) {
              tokenContract = new ethers.Contract(
                MAINNET_TOKENS_BY_SYMBOL[tokenSymbol.toLowerCase()].address,
                erc20Abi.abi,
                signer
              );
            } else {
              setMessages((prev) => [
                ...prev.slice(0, -1),
                {
                  sender: "assistant",
                  content: `
                  <div class="${styles.messageContent}">
                    <img src="/blint-landing/blormmy.png" alt="Blormmy" />
                    <p>Token not supported. Please provide a valid token symbol.</p>
                  </div>
                  `,
                },
              ]);
              return;
            }

            // Add message asking for token approval
            setMessages((prev) => [
              ...prev.slice(0, -1),
              {
                sender: "assistant",
                content: `
                <div class="${styles.messageContent}">
                  <img src="/blint-landing/blormmy.png" alt="Blormmy" />
                  <p>Awaiting confirmation of token approval...</p>
                </div>
                `,
              },
            ]);

            const decimals = await tokenContract.decimals();
            const approveTx = await tokenContract.approve(
              ethers.getAddress(recipient),
              BigInt(amount * 10 ** parseInt(decimals.toString()))
            );
            await approveTx.wait();
            console.log("Token allowance set.");

            const tx = await tokenContract.transfer(
              ethers.getAddress(recipient),
              ethers.parseUnits(
                amount.toString(),
                await tokenContract.decimals()
              )
            );
            console.log("Transaction sent:", tx);
          } catch (sendError) {
            console.error("Error sending token transaction:", sendError);
            setMessages((prev) => [
              ...prev.slice(0, -1),
              {
                sender: "assistant",
                content: `
                <div class="${styles.messageContent}">
                  <img src="/blint-landing/blormmy.png" alt="Blormmy" />
                  <p>Failed to send token transaction.</p>
                </div>
                `,
              },
            ]);
          }
        } else {
          // Incomplete send details, ask for more information
          setMessages((prev) => [
            ...prev.slice(0, -1),
            {
              sender: "assistant",
              content: `
              <div class="${styles.messageContent}">
                <img src="/blint-landing/blormmy.png" alt="Blormmy" />
                <p>Failed to send token transaction.</p>
              </div>
              `,
            },
          ]);
        }
      } else {
        // Unknown action
        setMessages((prev) => [
          ...prev.slice(0, -1),
          {
            sender: "assistant",
            content: `
            <div class="${styles.messageContent}">
              <img src="/blint-landing/blormmy.png" alt="Blormmy" />
              <p>I'm not sure how to assist with that.</p>
            </div>
            `,
          },
        ]);
      }
    } catch (error) {
      console.error("Error communicating with OpenAI:", error);
      setMessages((prev) => [
        ...prev.slice(0, -1),
        {
          sender: "assistant",
          content: `
          <div class="${styles.messageContent}">
            <img src="/blint-landing/blormmy.png" alt="Blormmy" />
            <p>Sorry, something went wrong.</p>
          </div>
          `,
        },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to handle Enter key press
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSend();
    }
  };

  // Function to handle token swap
  const handleSwap = async (tokenIn, tokenOut, amount) => {
    try {
      const sellToken = MAINNET_TOKENS_BY_SYMBOL[tokenIn.toLowerCase()];
      const buyToken = MAINNET_TOKENS_BY_SYMBOL[tokenOut.toLowerCase()];

      if (!sellToken || !buyToken) {
        throw new Error("Invalid token symbols provided.");
      }

      // Convert amount to proper decimal representation
      const sellAmount = BigInt(
        Math.floor(amount * 10 ** sellToken.decimals)
      ).toString();

      // 1. Get price quote first
      const priceParams = new URLSearchParams({
        chainId: "137", // Polygon
        sellToken: sellToken.address,
        buyToken: buyToken.address,
        sellAmount: sellAmount,
        takerAddress: wallet.accounts[0].address,
      });

      const headers = {
        "0x-api-key": process.env.REACT_APP_ZEROEX_API_KEY,
        "0x-version": "v2",
        Accept: "application/json",
      };

      // Check price first
      const priceResponse = await fetch(
        `https://api.0x.org/swap/permit2/price?${priceParams.toString()}`,
        { headers }
      );

      if (!priceResponse.ok) {
        const errorText = await priceResponse.text();
        throw new Error(`Price API error: ${errorText}`);
      }

      // 2. Set token allowance
      const provider = new ethers.BrowserProvider(wallet.provider);
      const signer = await provider.getSigner();
      const tokenContract = new ethers.Contract(
        sellToken.address,
        erc20Abi.abi,
        signer
      );

      setMessages((prev) => [
        ...prev.slice(0, -1),
        {
          sender: "assistant",
          content: `
        <div class="${styles.messageContent}">
          <img src="/blint-landing/blormmy.png" alt="Blormmy" />
          <p>Awaiting confirmation of token approval...</p>
        </div>
        `,
        },
      ]);

      const approveTx = await tokenContract.approve(
        PERMIT2_ADDRESS,
        ethers.MaxUint256 // Approve maximum amount
      );
      await approveTx.wait();

      // 3. Get firm quote
      const quoteParams = new URLSearchParams({
        chainId: "137",
        sellToken: sellToken.address,
        buyToken: buyToken.address,
        sellAmount: sellAmount,
        takerAddress: wallet.accounts[0].address,
      });

      const quoteResponse = await fetch(
        `https://api.0x.org/swap/permit2/quote?${quoteParams.toString()}`,
        { headers }
      );

      if (!quoteResponse.ok) {
        const errorText = await quoteResponse.text();
        throw new Error(`Quote API error: ${errorText}`);
      }

      const quoteData = await quoteResponse.json();

      // 4. Sign the Permit2 EIP-712 message
      const domain = quoteData.permit2.eip712.domain;
      const types = {
        PermitTransferFrom: quoteData.permit2.eip712.types.PermitTransferFrom,
        TokenPermissions: quoteData.permit2.eip712.types.TokenPermissions,
      };
      const message = quoteData.permit2.eip712.message;

      const signature = await signer.signTypedData(domain, types, message);

      // 5. Append signature length and data
      const signatureLengthInHex = numberToHex(size(signature), {
        signed: false,
        size: 32,
      });
      const transactionData = concat([
        ethers.getBytes(quoteData.transaction.data),
        ethers.getBytes(signatureLengthInHex),
        ethers.getBytes(signature),
      ]);

      // 6. Submit the transaction
      const tx = await signer.sendTransaction({
        to: quoteData.transaction.to,
        data: transactionData,
        gasLimit: quoteData.gas || undefined,
        chainId: 137,
      });

      console.log("Transaction sent:", tx);
      setMessages((prev) => [
        ...prev.slice(0, -1),
        {
          sender: "assistant",
          content: `
        <div class="${styles.messageContent}">
          <img src="/blint-landing/blormmy.png" alt="Blormmy" />
          <p>Swap transaction sent successfully! Transaction hash: ${tx.hash}</p>
        </div>
        `,
        },
      ]);
    } catch (error) {
      console.error("Error during swap:", error);
      setMessages((prev) => [
        ...prev.slice(0, -1),
        {
          sender: "assistant",
          content: `
        <div class="${styles.messageContent}">
          <img src="/blint-landing/blormmy.png" alt="Blormmy" />
          <p>Failed to perform swap: ${error.message}</p>
        </div>
        `,
        },
      ]);
    }
  };

  return (
    <div className={styles.container}>
      <Navbar />
      <PolygonSwitchComponent
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleSwitchNetwork}
      />
      <h1> Blormmy Brain </h1>
      <div className={styles.chatBox} ref={chatBoxRef}>
        {messages
          .filter((msg) => msg.sender !== "system") // Filter out system messages
          .map((msg, index) => (
            <div
              key={index}
              className={
                msg.sender === "user"
                  ? styles.userMessage
                  : styles.assistantMessage
              }
            >
              <strong>{msg.sender === "user" ? "You" : "Blormmy"}:</strong>{" "}
              <div dangerouslySetInnerHTML={{ __html: msg.content }} />
            </div>
          ))}
      </div>
      <div className={styles.inputArea}>
        <textarea
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleKeyPress}
          className={styles.input}
          placeholder="Type your message here..."
          style={{ resize: "none" }}
        />
        <button onClick={handleSend} className={styles.button}>
          Send
        </button>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import styles from "../styles/Home.module.css";
import logo from "../images/logo.png";
import arch from "../images/arch.png";
import { Link } from "react-router-dom";
import homeBg from "../images/home-bg.png";
import mobileBg from "../images/mobile-bg.png";
import xLogo from "../images/x.png";

const Home = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [isActionButtonTooltipVisible, setIsActionButtonTooltipVisible] =
    useState(false);

  const checkIfMobile = () => {
    if (typeof window !== "undefined") {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      const isMobileDevice =
        /android|iphone|ipad|iPod|opera mini|iemobile|wpdesktop/i.test(
          userAgent
        );
      setIsMobile(isMobileDevice);
    }
  };

  useEffect(() => {
    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);
    return () => window.removeEventListener("resize", checkIfMobile);
  }, []);

  const handleLogoMouseEnter = () => {
    setIsTooltipVisible(true);
  };

  const handleLogoMouseLeave = () => {
    setIsTooltipVisible(false);
  };

  const handleActionButtonMouseEnter = () => {
    setIsActionButtonTooltipVisible(true);
  };

  const handleActionButtonMouseLeave = () => {
    setIsActionButtonTooltipVisible(false);
  };

  if (isMobile) {
    return (
      <div className="container-mobile">
        {/* Mobile version */}
        <img
          className={styles.homeBg}
          src={mobileBg}
          alt="home background"
        ></img>
        <div className={styles.topleft}>
          <a
            href="https://x.com/blorm_"
            className={styles.topLeftImage}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className={styles.topleftImage} src={xLogo} alt="x logo" />
          </a>
        </div>
        <div className={styles.topCenter}>
          <span className={styles.topCenterText}>BLORM</span>
        </div>
        <div className={styles.topRight}>
          <a
            href="https://x.com/blorm_"
            className={styles.topRightImage}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className={styles.topRightImage} src={arch} alt="arch logo" />
          </a>
        </div>
        <div className={styles.center}>
          <Link to="/terms" className={styles.termsLink}>
            Terms & Conditions
          </Link>
          <video
            src="/blorm_animated_logo.mov"
            alt="Animated Logo"
            className={styles.centerImage}
            autoPlay={true}
            loop={true}
            controls={false}
            playsInline
            muted
          />
          <Link to="/blint" className={styles.actionButton}>
            ⟢ BLINT
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      {/* Desktop version */}
      <img className={styles.homeBg} src={homeBg} alt="home background"></img>
      <a
        href="https://x.com/blorm_"
        className={styles.topleft}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className={styles.topleft}>
          <img className={styles.topleftImage} src={xLogo} alt="x logo" />
          <span className={styles.topleftText}>&nbsp;/ BLORM</span>
        </div>
      </a>
      <div className={styles.topRight}>
        <span className={styles.topRightText}>BLORM EVERYTHING</span>
        <span className={styles.topRightText}>A BLORM IS A BLORM</span>
      </div>
      <div className={styles.bottomLeft}>
        <Link to="/terms" className={styles.termsLink}>
          TERMS
        </Link>
      </div>
      <div className={styles.bottomRight}>
        <Link
          to="/blint"
          className={styles.actionButton}
          onMouseEnter={handleActionButtonMouseEnter}
          onMouseLeave={handleActionButtonMouseLeave}
        >
          ⟢ BLINT
        </Link>
        {isActionButtonTooltipVisible && (
          <div className={styles.tooltipButton}>
            mint a nft through the magic of blorm
          </div>
        )}
      </div>
      <div className={styles.center}>
        <video
          src="/blorm_animated_logo.webm"
          alt="Animated Logo"
          className={styles.centerImage}
          autoPlay
          muted
          loop
          controls={false}
          playsInline
          onMouseEnter={handleLogoMouseEnter}
          onMouseLeave={handleLogoMouseLeave}
        />
        <span className={styles.centerText}>B L O R M</span>
        {isTooltipVisible && (
          <div className={styles.tooltipLogo}>
            blorm gets activities onchain
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;

// src/utils/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, signInWithCustomToken, setPersistence, browserLocalPersistence } from "firebase/auth";
import { getFunctions, httpsCallable } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyCGTo_hDs1VVcSduU6xOyM2JrUYamHRgv4",
  authDomain: "blorm-gcp.firebaseapp.com",
  projectId: "blorm-gcp",
  storageBucket: "blorm-gcp.appspot.com",
  messagingSenderId: "813863109935",
  appId: "1:813863109935:web:3a232d58f4c1913bfe0dda",
  measurementId: "G-8B9HL03XBB"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app, "blorm-db-1");
const auth = getAuth(app);
const functions = getFunctions(app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    // Now the user's session will persist across refreshes
  })
  .catch((error) => {
    console.error('Error setting persistence:', error);
  });
export { db, auth, functions, signInWithCustomToken, httpsCallable };


import React, { useState, useEffect } from 'react';
import styles from '../styles/ProfilePicDropdown.module.css';

const ProfilePicDropdown = ({ options, value, onChange }) => {
  // State to manage the selected profile image
  const [selectedImage, setSelectedImage] = useState(value);  // Initially set to the current profile image
  const [isOpen, setIsOpen] = useState(false);

  // Handle the selection of an option from the dropdown
  const handleSelect = (option) => {
    const newImage = option.metadata?.image?.pngUrl || '';  // Ensure metadata and image exist
    if (newImage) {
      setSelectedImage(newImage);  // Update local state with the selected image
      onChange(newImage);  // Pass the selected image URL back to the parent component
    } else {
      console.log("Option does not have a valid image URL:", option);
    }
    setIsOpen(false);  // Close the dropdown
  };

  // Set the default image based on the initial value prop
  useEffect(() => {
    setSelectedImage(value);  // Set to the passed-in profile image on component load or when value changes
  }, [value]);

  return (
    <div className={styles.dropdown}>
      {/* Selected option section */}
      <div className={styles.selected} onClick={() => setIsOpen(!isOpen)}>
        {selectedImage ? (
          <div className={styles.optionContent}>
            {/* Display the current profile picture and the text 'CURRENT' */}
            <img src={selectedImage} alt="Current profile" className={styles.nftOptionImage} />
            <span>SELECTED</span>
          </div>
        ) : (
          "Select a profile picture"
        )}
      </div>

      {/* Dropdown options */}
      {isOpen && (
        <div className={styles.options}>
          {options.map((option) => (
            <div
              key={option.label}
              className={styles.option}
              onClick={() => handleSelect(option)}
            >
              <div className={styles.optionContent}>
                {/* Null check to ensure option.metadata and option.metadata.image are defined */}
                <img
                  src={option.metadata?.image?.pngUrl || 'default-image.png'}  // Use a fallback image if pngUrl is missing
                  alt={option.label}
                  className={styles.nftOptionImage}
                />
                {option.label}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProfilePicDropdown;

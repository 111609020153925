import React, { useState, useEffect, useRef } from 'react';
import styles from '../styles/Profile.module.css';
import { ethers } from 'ethers';
import Navbar from '../components/Navbar';
import onft721 from '../utils/BLORM_ONFT721.json';
import blintCollections from '../utils/blintCollections.json';
import { Grid, List, ChevronDown, Loader } from 'lucide-react';
import polygonLogo from '../images/polygon.png';
import { Alchemy, Network } from 'alchemy-sdk';
import { useParams } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { db } from '../utils/firebase';

const getPinataGateway = (url) => {
  if (url && url.startsWith('https://ipfs.io/ipfs/')) {
    const ipfsHash = url.replace('https://ipfs.io/ipfs/', '');
    return `${process.env.REACT_APP_GATEWAY_URL}/ipfs/${ipfsHash}`;
  }
  return url;
};

export default function ProfilePublic() {
  const { userAddress } = useParams();
  console.log('User address:', userAddress);
  const [profile, setProfile] = useState(undefined); // undefined while loading, null if not found
  const [nftData, setNftData] = useState([]);
  const [activeCollection, setActiveCollection] = useState("ALL");
  const [viewMode, setViewMode] = useState('grid');
  const [chainsWithNfts, setChainsWithNfts] = useState(new Set());
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [loadingNfts, setLoadingNfts] = useState(false);

  const settings = {
    apiKey: process.env.REACT_APP_ALCHEMY_APY_KEY,
    network: Network.MATIC_MAINNET,
  };

  const alchemy = new Alchemy(settings);

  const tvlRefs = useRef({});
  const yieldRefs = useRef({});

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const profileRef = doc(db, 'profiles', userAddress.toLowerCase());
        const profileSnap = await getDoc(profileRef);
        if (profileSnap.exists()) {
          setProfile(profileSnap.data());
        } else {
          setProfile(null); // Profile not found
        }
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    };
    
    fetchProfile();
  }, [userAddress]);

  useEffect(() => {
    if (profile && profile.eth_address) {
      fetchNfts();
    }
  }, [profile]);

  const fetchNfts = async () => {
    if (!profile || !profile.eth_address) {
      return;
    }

    setLoadingNfts(true);
    const ownerAddress = profile.eth_address;
    const allNfts = [];

    const fetchPage = async (pageKey = null) => {
      try {
        const response = await alchemy.nft.getNftsForOwner(ownerAddress, {
          pageSize: 100,
          pageKey: pageKey
        });

        const filteredResponseNfts = response.ownedNfts.filter(nft =>
          blintCollections.some(collection => collection.contractAddress.toLowerCase() === nft.contract.address.toLowerCase())
        );

        const nftsWithMetadata = filteredResponseNfts.map(nft => ({
          tokenId: nft.tokenId,
          contractAddress: nft.contract.address,
          metadata: nft,
          collectionName: nft.contract.name || nft.collection?.name,
          imageUrl: getPinataGateway(nft.image?.pngUrl || nft.image?.cachedUrl || nft.image?.originalUrl),
          uniqueKey: `polygon-${nft.tokenId}-${nft.contract.address}`,
        }));

        allNfts.push(...nftsWithMetadata);

        if (response.pageKey) {
          await fetchPage(response.pageKey);
        }
      } catch (error) {
        console.error('Error fetching NFTs:', error);
      }
    };

    try {
      await fetchPage();
      setNftData(allNfts);
    } finally {
      setLoadingNfts(false);
    }
  };

  const fetchTvl = async (contract, userAddress, tokenId) => {
    try {
      const tvl = await contract.getSingleAccountBalance(userAddress, tokenId);
      return ethers.formatUnits(tvl, 6);
    } catch (error) {
      console.error("Error fetching TVL:", error);
      return 'N/A';
    }
  };

  const fetchYield = async (contract, userAddress, tokenId) => {
    try {
      const yieldValue = await contract.getSingleAccountYield(userAddress, tokenId);
      return ethers.formatUnits(yieldValue, 6);
    } catch (error) {
      console.error("Error fetching Yield:", error);
      return 'N/A';
    }
  };

  const handleCollectionChange = (collection) => {
    setActiveCollection(collection);
    setIsDropdownOpen(false);
  };

  const filteredNfts = activeCollection === "ALL"
    ? nftData
    : nftData.filter(nft => nft.contractAddress.toLowerCase() === activeCollection.toLowerCase());

  const abbreviateAddress = (address) => {
    if (!address) return '';
    return `${address.slice(0, 6)}...${address.slice(-5)}`;
  };

  useEffect(() => {
    if (!profile || !profile.eth_address) {
      return; // Exit early if profile or profile.eth_address is undefined
    }

    const interval = setInterval(async () => {
      for (const nft of nftData) {
        try {
          const provider = new ethers.JsonRpcProvider('https://polygon-rpc.com/');
          const contract = new ethers.Contract(nft.contractAddress, onft721.abi, provider);
          const newTvl = await fetchTvl(contract, profile.eth_address, nft.tokenId);
          const newYield = await fetchYield(contract, profile.eth_address, nft.tokenId);

          if (tvlRefs.current[nft.uniqueKey]) {
            tvlRefs.current[nft.uniqueKey].innerText = `${newTvl} USDC`;
            tvlRefs.current[nft.uniqueKey].classList.add(styles.blink);
          }
          if (yieldRefs.current[nft.uniqueKey]) {
            yieldRefs.current[nft.uniqueKey].innerText = `${newYield} USDC`;
            yieldRefs.current[nft.uniqueKey].classList.add(styles.blink);
          }

          setTimeout(() => {
            if (tvlRefs.current[nft.uniqueKey]) {
              tvlRefs.current[nft.uniqueKey].classList.remove(styles.blink);
            }
            if (yieldRefs.current[nft.uniqueKey]) {
              yieldRefs.current[nft.uniqueKey].classList.remove(styles.blink);
            }
          }, 1000);
        } catch (error) {
          console.error('Error updating TVL and Yield:', error);
        }
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [nftData, profile]); // Remove profile.eth_address from dependencies

  if (profile === undefined) {
    // Profile is loading
    return (
      <div className={styles.container}>
        <Navbar />
        <div className={styles.profileTop}>
          <h2>Loading...</h2>
        </div>
      </div>
    );
  } else if (profile === null) {
    // Profile not found
    return (
      <div className={styles.container}>
        <Navbar />
        <div className={styles.profileTop}>
          <h2>Profile not found</h2>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Navbar />
      <div className={styles.profileTop}>
        <div className={styles.profilePicture}>
          <img src={profile.profilePicture} alt="Profile" className={styles.profileImage} />
        </div>
        <div className={styles.profileInfo}>
          <h2 className={styles.profileName}>{profile.name}</h2>
          <p className={styles.profileBio}>{profile.bio}</p>
          <p className={styles.profileAddress}>{abbreviateAddress(profile.eth_address)}</p>
        </div>
        <div className={styles.chainIcons}>
          {Array.from(chainsWithNfts).map(chainId => (
            <img key={chainId} src={polygonLogo} alt="Polygon" className={styles.chainIcon} />
          ))}
        </div>
      </div>

      {loadingNfts ? (
        <div className={styles.loadingContainer}>
          <Loader className={styles.loadingAnimation} />
        </div>
      ) : nftData.length > 0 ? (
        <div className={styles.nftsContainer}>
          <h3 className={styles.blintTitle}>BLINTS</h3>
          <div className={styles.nftsToolbar}>
            <div className={styles.nftsToolbarLeft}>
              <div className={styles.collectionDropdownContainer}>
                <div
                  className={styles.collectionDropdown}
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                >
                  <div className={styles.selectedOption}>
                    {activeCollection === "ALL" ? (
                      <span>ALL</span>
                    ) : (
                      <>
                        <img
                          src={blintCollections.find(c => c.contractAddress === activeCollection)?.image}
                          alt={activeCollection}
                          className={styles.collectionImage}
                        />
                        <span>{blintCollections.find(c => c.contractAddress === activeCollection)?.title || activeCollection}</span>
                      </>
                    )}
                  </div>
                  <ChevronDown size={20} />
                </div>

                {isDropdownOpen && (
                  <div className={styles.dropdownMenu}>
                    <div
                      className={styles.dropdownItem}
                      onClick={() => handleCollectionChange("ALL")}
                    >
                      <span className={styles.collectionTitle}>ALL</span>
                    </div>

                    {blintCollections.map(collection => (
                      <div
                        key={collection.contractAddress}
                        className={styles.dropdownItem}
                        onClick={() => handleCollectionChange(collection.contractAddress)}
                      >
                        <img src={collection.image} alt={collection.name} className={styles.collectionImage} />
                        <span className={styles.collectionTitle}>{collection.title || collection.contractAddress}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className={styles.viewToggle}>
              <button onClick={() => setViewMode('grid')} className={viewMode === 'grid' ? styles.active : ''}>
                <Grid size={24} />
              </button>
              <button onClick={() => setViewMode('list')} className={viewMode === 'list' ? styles.active : ''}>
                <List size={24} />
              </button>
            </div>
          </div>
          <div className={styles.nftContainer}>
            {viewMode === 'grid' ? (
              <div className={styles.nftGrid}>
                {filteredNfts.map(nft => (
                  <div key={nft.uniqueKey} className={styles.nftCard}>
                    <img
                      src={nft.imageUrl}
                      alt={nft.metadata.name || 'NFT Image'}
                      className={styles.nftCardImage}
                    />
                    <div className={styles.nftDetails}>
                      <div className={styles.nftField}><strong>COLLECTION:</strong> {nft.metadata.collection?.name}</div>
                      <div className={styles.nftField}><strong>TOKEN ID:</strong> {nft.tokenId}</div>
                      <div className={styles.nftField}>
                        <strong>CHAIN:</strong>
                        <div>
                          Polygon <img src={polygonLogo} alt="Polygon" className={styles.cardChainIcon} />
                        </div>
                      </div>
                      <div className={styles.nftField}><strong>TVL:</strong> <span ref={el => tvlRefs.current[nft.uniqueKey] = el}>{nft.tvl} USDC</span></div>
                      <div className={styles.nftField}><strong>YIELD:</strong> <span ref={el => yieldRefs.current[nft.uniqueKey] = el}>{nft.yieldValue} USDC</span></div>
                    </div>

                  </div>
                ))}
              </div>
            ) : (
              <div className={styles.nftListWrapper}>
                <table className={styles.nftList}>
                  <thead>
                    <tr>
                      <th>Image</th>
                      <th>Collection</th>
                      <th>Token ID</th>
                      <th>TVL</th>
                      <th>Yield</th>
                      <th>Chain</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredNfts.map((nft) => (
                      <tr key={nft.uniqueKey}>
                        <td>
                          <img
                            src={nft.imageUrl}
                            alt={nft.metadata.name}
                            className={styles.nftImage} />
                        </td>
                        <td>{nft.collectionName}</td>
                        <td>{nft.tokenId}</td>
                        <td ref={el => tvlRefs.current[nft.uniqueKey] = el}>{nft.tvl} USDC</td>
                        <td ref={el => yieldRefs.current[nft.uniqueKey] = el}>{nft.yieldValue} USDC</td>
                        <td>
                          <img src={polygonLogo} alt="Polygon" className={styles.chainIcon} /> {nft.chain}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className={styles.noBlintsFound}>
          <h3>No blints found</h3>
        </div>
      )}
    </div>
  );
}

import React from "react";
import styled from "styled-components";

const Container = styled.div`
  font-family: "Inter", -apple-system, BlinkMacSystemFont, sans-serif;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  color: #333;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const Title = styled.h1`
  text-align: center;
  color: #1a1a1a;
  margin-bottom: 2rem;
`;

const Subtitle = styled.h2`
  color: #1a1a1a;
  margin-top: 2rem;
  font-size: 1.25rem;
`;

const DisclaimerSection = styled.div`
  margin-bottom: 2rem;
`;

const ContactInfo = styled.div`
  margin-top: 3rem;
  padding-top: 1rem;
  border-top: 1px solid #eee;
  font-style: italic;
`;

const Terms = () => {
  return (
    <Container>
      <Title>Terms and Conditions</Title>

      <DisclaimerSection>
        <p>
          Terms and Conditions This disclaimer governs the use of services
          provided by Blorm, a company dedicated to the development of AI agents
          fine-tuned from Large Language Models (LLMs) and integrated as part of
          a non-fungible token (NFT) collection. Each NFT issued by Blorm grants
          ownership of a unique AI agent with autonomous control over associated
          social media accounts (e.g., Twitter) and cryptocurrency wallets.
          Please read this disclaimer carefully before participating in any
          activities or transactions on the Blorm platform. By using Blorm's
          services, you acknowledge and agree to the terms and conditions set
          forth herein.
        </p>

        <p>
          1. No Financial Advice
          <br />
          The AI agents created and deployed by Blorm are programmed to operate
          autonomously, making independent decisions that may involve financial
          transactions or disseminating content. These AI agents are not
          designed, nor should they be construed, as financial advisors or
          fiduciaries. Any content, decisions, or actions taken by the AI agents
          do not constitute financial, investment, legal, or tax advice. Blorm,
          its contributors, officers, agents, and affiliates expressly disclaim
          any responsibility or liability for any financial, investment, or
          business decisions you may make based on the actions, output, or
          recommendations of the AI agents. By interacting with the AI agents,
          you acknowledge that you bear full responsibility for any decisions or
          actions you take, whether in response to AI-driven activity or
          otherwise.
        </p>

        <p>
          2. No Guarantee of Financial Returns
          <br />
          Blorm's platform includes a mechanism for minting NFTs that involve a
          one-time minting fee. Minting fees collected may be deployed to
          decentralized finance (DeFi) protocols, where they may earn Annual
          Percentage Rates (APR) or other forms of yield. The performance of
          such investments is inherently speculative and subject to numerous
          risks, including but not limited to fluctuations in the value of
          cryptocurrencies, market volatility, and liquidity risks. By engaging
          in minting or holding NFTs on the Blorm platform, you understand and
          accept that there is no guarantee of financial returns, profit, or
          appreciation in value. Past performance is not indicative of future
          results, and investments in DeFi protocols involve a high degree of
          risk. Blorm assumes no responsibility for the financial performance of
          these protocols or the NFTs.
        </p>

        <p>
          3. Assumption of Risk and User Responsibility
          <br />
          By participating in the minting, buying, selling, or trading of NFTs
          on the Blorm platform, you acknowledge that you are acting of your own
          volition and assume all risks associated with such activities. These
          risks include, but are not limited to, market risks, technological
          risks, cybersecurity risks, regulatory changes, liquidity risks, and
          the potential loss of the entire value of your digital assets. Blorm
          disclaims any responsibility for losses or damages, whether direct,
          indirect, incidental, consequential, or otherwise, arising from your
          use of the platform, interactions with AI agents, reliance on AI
          output, or participation in DeFi protocols. You are solely responsible
          for conducting your own due diligence, research, and analysis before
          making any decision to engage with Blorm's products or services.
        </p>

        <p>
          4. Smart Contract Risks and Limitations
          <br />
          Blorm utilizes blockchain-based smart contracts to facilitate the
          operation of its platform, including the issuance and management of
          NFTs, AI agents, and the deployment of funds into DeFi protocols.
          While reasonable efforts are made to audit and test these smart
          contracts for security vulnerabilities, Blorm cannot and does not
          guarantee the absence of bugs, exploits, vulnerabilities, or
          unforeseen failures in the code. Smart contract interactions are
          irreversible, and once a transaction is executed, it cannot be undone
          or reversed. You accept that there may be risks associated with smart
          contracts, including but not limited to coding errors, operational
          errors, unexpected behavior, or attacks by malicious actors. Blorm,
          its developers, contributors, affiliates, or agents are not liable for
          any losses or damages resulting from smart contract interactions.
        </p>

        <p>
          5. No Legal, Tax, or Regulatory Advice
          <br />
          The content provided by Blorm and the actions of its AI agents do not
          constitute legal, tax, regulatory, or compliance advice. You are
          encouraged to consult with qualified professionals before making any
          decisions based on your interaction with Blorm's services or the AI
          agents. Participation in the Blorm ecosystem may have legal, tax, or
          regulatory implications based on your jurisdiction. It is your sole
          responsibility to ensure compliance with applicable laws, rules, and
          regulations in your jurisdiction. Blorm does not guarantee that its
          services are lawful in every jurisdiction or that participation is
          without legal or regulatory risk. You acknowledge that you are
          responsible for understanding and complying with all relevant laws and
          regulations.
        </p>

        <p>
          6. Limitation of Liability
          <br />
          To the fullest extent permitted by law, Blorm, its officers,
          directors, employees, contractors, contributors, affiliates, and
          agents are not liable for any direct, indirect, incidental,
          consequential, punitive, or special damages of any kind, including but
          not limited to lost profits, lost revenue, data loss, or business
          interruption, arising out of or related to the use or inability to use
          the Blorm platform, the actions of AI agents, smart contract
          interactions, or participation in DeFi protocols. Blorm's liability,
          if any, shall be limited to the amount you have paid to Blorm, if any,
          for the specific transaction that forms the basis of the claim.
        </p>

        <p>
          7. Indemnification
          <br />
          You agree to indemnify, defend, and hold harmless Blorm, its officers,
          directors, employees, agents, and affiliates from and against any and
          all claims, liabilities, damages, losses, costs, or expenses
          (including reasonable attorney's fees) arising out of or related to
          your use of the Blorm platform, your interactions with AI agents, or
          your violation of this Disclaimer or applicable law.
        </p>

        <p>
          8. Changes to this Disclaimer
          <br />
          Blorm reserves the right to amend or modify this Disclaimer at any
          time, with or without notice. It is your responsibility to review this
          Disclaimer periodically for updates or changes. Continued use of the
          Blorm platform after any changes to this Disclaimer constitutes your
          acceptance of those changes.
        </p>

        <p>
          9. Governing Law and Jurisdiction
          <br />
          This Disclaimer and any disputes arising out of or related to your use
          of the Blorm platform shall be governed by and construed in accordance
          with the laws. You agree to submit to the exclusive jurisdiction of
          the courts for any legal matters arising from or related to this
          Disclaimer.
        </p>

        <p>
          10. Interactions with AI Agents
          <br />
          Blorm's AI agents are designed to operate autonomously, including, but
          not limited to, generating content, executing financial transactions,
          interacting on social media platforms, and communicating with third
          parties. By engaging with these AI agents, you acknowledge and accept
          that Blorm does not have direct oversight or control over the
          autonomous actions performed by these agents. Blorm explicitly
          disclaims any responsibility or liability for the interactions,
          communications, actions, or omissions of its AI agents, including any
          third-party interactions, communications made by the AI agents, or any
          consequences resulting from these engagements. Users and third parties
          who choose to interact with these AI agents do so at their own risk.
          Blorm is not responsible for any content generated by AI agents, nor
          for any actions they undertake, including but not limited to financial
          transactions, social media posts, or any form of communication. Blorm
          is also not liable for any offensive, inappropriate, misleading, or
          unlawful content generated by these AI agents. You acknowledge that
          AI-generated content may not always align with Blorm's views or
          intentions, and you accept that Blorm cannot and does not guarantee
          the reliability, accuracy, legality, or appropriateness of such
          content. By using or interacting with Blorm's AI agents, you accept
          full responsibility for your engagement and release Blorm, its
          officers, directors, employees, contractors, contributors, affiliates,
          and agents from any and all claims, liabilities, and damages arising
          out of or related to these interactions. This addition serves to
          ensure that Blorm and its contributors are not held accountable for
          any outcomes from autonomous AI interactions, providing an extra layer
          of protection for the company. For inquiries or further assistance,
          please contact Blorm's support team
        </p>
      </DisclaimerSection>

      <ContactInfo>
        <p>
          If you have any questions or concerns about these terms and
          conditions, please contact us at:
        </p>
        <p>Email: info@blorm.com</p>
      </ContactInfo>
    </Container>
  );
};

export default Terms;

import { ethers } from 'ethers';
import ONFT721 from './BLORM_ONFT721.json';
import UploadToIPFS from './UploadToIPFS';
const chainListUrl = 'https://chainid.network/chains.json';

const fetchChainData = async () => {
    try {
        const response = await fetch(chainListUrl);
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching chain data:', error);
        throw error;
    }
};

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const getLastTokenId = async (contract, address) => {
    const tokens = await contract.getTokensByOwner(address);
    return tokens.length > 0 ? tokens[tokens.length - 1] : null;
};

export const mintTokenWithWallet = async (metadata, contractAddress, wallet, quantity) => {
    const chainData = await fetchChainData();
    
    // Find the Polygon chain information
    const polygonChainInfo = chainData.find(chain => chain.name.toLowerCase().includes('polygon') && chain.chainId === 137);
    if (!polygonChainInfo) {
        throw new Error('Polygon chain not found in the chain list');
    }

    const polygonChainParams = {
        chainId: ethers.toQuantity(polygonChainInfo.chainId),
        chainName: polygonChainInfo.name,
        nativeCurrency: polygonChainInfo.nativeCurrency,
        rpcUrls: polygonChainInfo.rpc,
        blockExplorerUrls: polygonChainInfo.explorers.map(explorer => explorer.url),
    };

    try {
        // Switch to Polygon network
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: polygonChainParams.chainId }],
            });
        } catch (switchError) {
            if (switchError.code === 4902) {
                // Chain not added to wallet, add it
                try {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [polygonChainParams],
                    });
                } catch (addError) {
                    throw new Error("Failed to add the Polygon chain to the wallet");
                }
            } else {
                throw new Error("Failed to switch to the Polygon chain");
            }
        }

        const provider = new ethers.BrowserProvider(wallet.provider);
        await provider.send("eth_requestAccounts", []);
        const signer = await provider.getSigner();
        const recipientAddress = await signer.getAddress();
        const contract = new ethers.Contract(contractAddress, ONFT721.abi, signer);
        const contractName = await contract.name();

        if (!ethers.isAddress(recipientAddress)) {
            throw new Error("Invalid recipient address");
        }

        const metadataURI = await UploadToIPFS(JSON.stringify(metadata), true);
        const mintFee = ethers.parseUnits((quantity * 2).toString(), "ether");
        let gasLimit = 3500000;
        if (quantity > 19) {
            gasLimit = 29000000;
        } else if (quantity > 9) {
            gasLimit = 12000000;
        } else if (quantity > 4) {
            gasLimit = 8000000;
        }

        // Start the minting process
        const transaction = await contract.mintDepositBatch(recipientAddress, metadataURI, quantity, { value: mintFee, gasLimit: gasLimit });
        const receipt = await transaction.wait();
        console.log('mintToken receipt', receipt);

        // Get the transaction hash
        const txHash = receipt.hash;

        // Fetch the transaction receipt and parse the logs
        const txReceipt = await provider.getTransactionReceipt(txHash);
        console.log('mintToken txReceipt', txReceipt);

        // Parse the Transfer event from the logs
        const iface = new ethers.Interface([
            "event Transfer(address indexed from, address indexed to, uint256 indexed tokenId)"
        ]);

        let tokenId;
        for (const log of txReceipt.logs) {
            try {
                const parsedLog = iface.parseLog(log);
                if (parsedLog.name === "Transfer" && 
                    parsedLog.args.from === ethers.ZeroAddress && 
                    parsedLog.args.to.toLowerCase() === recipientAddress.toLowerCase()) {
                    tokenId = parsedLog.args.tokenId.toString();
                    break;
                }
            } catch (error) {
                // Ignore logs that don't match the Transfer event
            }
        }

        if (!tokenId) {
            throw new Error('Unable to find the minted token ID in the transaction logs.');
        }

        // Return the token ID, transaction hash, and contract name
        console.log('Mint success with data:', tokenId, txHash, contractName);
        return [tokenId, txHash, contractName];

    } catch (error) {
        console.error('Error minting token:', error);
        throw error;
    }
};

